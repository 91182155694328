import React from 'react';
import PropTypes from 'prop-types';

import * as utils from '@/utils';

const Comment = ({
  author,
  content,
  created,
}) => (
  <article className='comment'>
    <aside className='comment__info'>
      <p className='comment__author'>{author.name}</p>
      <p className='comment__date'>{utils.getDisplayDateWithTime(created)}</p>
    </aside>
    <div
      className='comment__text'
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: content }}
    />
  </article>
);

Comment.propTypes = {
  author: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
  created: PropTypes.number.isRequired,
};

export default Comment;
