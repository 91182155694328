import * as t from '@/actions/types';
import { SYNC_COUNTRIES } from '@/constants/routes';

const initialState = {
  isSynced: false,
  syncedCountries: null,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  if (type === t.SEND_REQUEST && payload.dataType === SYNC_COUNTRIES && payload.responseData.success) {
    return {
      isSynced: true,
      syncedCountries: payload.responseData.syncedCountries,
    };
  }

  return state;
};

export const getSyncedCountries = state => state.admin.countries.syncedCountries;
export const isCountriesSynced = state => state.admin.countries.isSynced;
