import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as utils from '@/utils';
import * as s from '@/selectors';

import OverviewMonth from './OverviewMonth';
import OverViewDayGrid from './OverviewDayGrid';
import OverviewEventGrid from './OverviewEventGrid';

const calendarMonths = utils.getAllCalendarMonths();

const mapStateToProps = state => ({
  monthEvents: s.getCalendarEventsByDate(state),
  overviewPhotos: s.getOverviewPhotos(state),
});

const OverviewCalendar = ({ monthEvents, overviewPhotos }) => {
  const [focusedEvent, setFocusEvent] = useState(null);

  return (
    <section className='content-wrapper'>
      <div className='overview'>
        {calendarMonths.map(m => (
          <OverviewMonth
            key={`month-${m.month}-${m.year}`}
            date={m}
            photo={overviewPhotos[m.year] ? overviewPhotos[m.year][m.month] : {}}
            title={utils.getDisplayMonth(m)}
          >
            <OverViewDayGrid date={m} />

            {monthEvents[m.year] && (
              <OverviewEventGrid
                key={`events-${m.year}-${m.month}`}
                events={monthEvents[m.year][m.month]}
                focusedEvent={focusedEvent}
                month={m.month}
                year={m.year}
                onFocusEvent={setFocusEvent}
              />
            )}
          </OverviewMonth>
        ))}
      </div>
    </section>
  );
};

OverviewCalendar.propTypes = {
  monthEvents: PropTypes.object.isRequired,
  overviewPhotos: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(OverviewCalendar);
