import * as t from '@/actions/types';
import {
  clearForm,
  showAlert,
  loginUser,
} from '@/actions';
import * as c from '@/constants/dataTypes';
import * as forms from '@/constants/formNames';
import * as s from '@/selectors';
import * as routes from '@/constants/routes';
import * as k from '@/constants/keywords';
import {
  ALERT_LEVEL,
  ERRORS,
  VALIDATION,
} from '@/constants/alerts';

export const submitLogin = (user, password) => ({
  type: t.SEND_REQUEST,
  payload: {
    method: 'post',
    dataType: c.AUTH,
    requestData: {
      user,
      password,
    },
    successFns: [
      (dispatch, { token }) => dispatch(loginUser(token)),
    ],
    errorFns: [
      (dispatch, error) => {
        if (error.response.status === 401) {
          dispatch(showAlert(ERRORS.CREDENTIALS));
        } else {
          dispatch(showAlert(ERRORS.GENERIC));
        }
      },
    ],
  },
});

export const fetchPhotoAlbums = pageToken => ({
  type: t.SEND_REQUEST,
  payload: {
    isInternal: false,
    isGoogle: true,
    requestData: { pageSize: 50, pageToken },
    url: routes.PHOTO_ALBUMS,
  },
});

export const syncPhotoAlbum = albumId => ({
  type: t.SEND_REQUEST,
  payload: {
    isGoogle: true,
    dataType: routes.SYNC_PHOTOS,
    requestData: { albumId },
    useToken: true,
  },
});

export const deletePhoto = () => (dispatch, getState) => {
  const { photoId } = s.getFormInput(getState(), forms.DELETE_PHOTO);

  if (!photoId) {
    return dispatch(showAlert(VALIDATION.PHOTO_ID, ALERT_LEVEL.VALIDATION));
  }

  return dispatch({
    type: t.SEND_REQUEST,
    payload: {
      method: 'delete',
      dataType: routes.PHOTOS,
      requestData: { photoId },
      useToken: true,
      successFns: [
        () => dispatch(clearForm(forms.DELETE_PHOTO)),
      ],
    },
  });
};

export const syncCountries = () => ({
  type: t.SEND_REQUEST,
  payload: {
    dataType: routes.SYNC_COUNTRIES,
    useToken: true,
  },
});

export const syncEvents = () => ({
  type: t.SEND_REQUEST,
  payload: {
    isGoogle: true,
    dataType: routes.SYNC_EVENTS,
    useToken: true,
  },
});

export const fetchAllTexts = () => ({
  type: t.SEND_REQUEST,
  payload: {
    dataType: c.TEXTS,
    requestData: { type: k.ALL },
    useToken: true,
  },
});

export const saveText = (textType, date, eventId, content) => ({
  type: t.SEND_REQUEST,
  payload: {
    method: 'post',
    dataType: c.TEXTS,
    requestData: {
      type: textType,
      date,
      eventId,
      content,
    },
    useToken: true,
  },
});

export const changeAdminTextTab = textType => ({
  type: t.CHANGE_ADMIN_TEXT_TAB,
  payload: { textType },
});

export const selectTextDate = date => ({
  type: t.SELECT_TEXT_DATE,
  payload: {
    date: {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth(),
      day: date.getUTCDate(),
    },
  },
});

export const selectTextEvent = eventId => ({
  type: t.SELECT_TEXT_EVENT,
  payload: { eventId },
});
