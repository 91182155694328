import { combineReducers } from 'redux';

import { default as cacheInfo } from './cacheInfo';
import { default as comments } from './comments';
import { default as countries } from './countries';
import { default as events } from './events';
import { default as overview } from './overview';
import { default as photos } from './photos';
import { default as texts } from './texts';

export default combineReducers({
  cacheInfo,
  comments,
  countries,
  events,
  overview,
  photos,
  texts,
});

export * from './cacheInfo';
export * from './comments';
export * from './countries';
export * from './events';
export * from './overview';
export * from './photos';
export * from './texts';
