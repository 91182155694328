/* eslint-disable react/jsx-no-bind */
import React, { useMemo } from 'react';
import Link from 'redux-first-router-link';
import PropTypes from 'prop-types';

import * as utils from '@/utils';

import { EVENT } from '@/constants/routes';

const OverviewEventGrid = ({
  events,
  focusedEvent,
  month,
  year,
  onFocusEvent,
}) => {
  const eventNames = new Set();
  const renderEventName = e => {
    if (e.summary && !eventNames.has(e.summary) && e.length > 2) {
      eventNames.add(e.summary);

      return e.summary.split(',')[0];
    }
    return null;
  };
  const offset = useMemo(() => utils.getWeekOffset(month, year), [month, year]);
  return (
    <section className='ov-grid'>
      {events.map((e, i) => {
        let className = 'ov-grid__event';
        let backgroundImage;

        if (e.isDummyEvent) {
          className += ' dummy-event';
        } else {
          if (e._id === focusedEvent) {
            className += ` ov-grid__event--${e.colorId}`;
          } else {
            className += ' ov-grid__event--neutral';
          }

          const countryName = e.summary.split(', ').pop();
          backgroundImage = `url('/flags/${countryName}.svg')`;
        }

        return (
          <Link
            // eslint-disable-next-line react/no-array-index-key
            key={`${e._id}-${i}`}
            className={className}
            role='button'
            style={{
              gridColumnStart: i === 0 ? offset + 1 : '',
              gridColumnEnd: `span ${e.length}`,
              backgroundImage,
            }}
            tabIndex={0}
            to={{ type: EVENT, payload: { eventId: e._id } }}
            onBlur={() => onFocusEvent(null)}
            onFocus={() => onFocusEvent(e._id)}
            onMouseOut={() => onFocusEvent(null)}
            onMouseOver={() => onFocusEvent(e._id)}
          >
            <span className='event-name'>{renderEventName(e)}</span>
          </Link>
        );
      })}
    </section>
  );
};

OverviewEventGrid.whyDidYouRender = true;

OverviewEventGrid.propTypes = {
  events: PropTypes.array.isRequired,
  focusedEvent: PropTypes.string,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  onFocusEvent: PropTypes.func.isRequired,
};

OverviewEventGrid.defaultProps = {
  focusedEvent: null,
};

export default OverviewEventGrid;
