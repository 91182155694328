import {
  compareMonths,
  fitEventToMonth,
  getLastDayOfMonth,
} from './index';
import { BEGINNING_OF_TIME } from '@/constants/timeBounds';

const WEEK_DAYS = 7;

export const getCalendarEvents = (month, year, events, offset) => {
  let eventLengthTotal = 0;

  const calendarEvents = events.reduce((filteredEvents, event) => {
    const date = { month, year };
    const startsBeforeOrNow = compareMonths(event.start, date) <= 0;
    const endsNowOrAfter = compareMonths(event.end, date) >= 0;

    if (startsBeforeOrNow && endsNowOrAfter) {
      const lengthInMonth = fitEventToMonth(event, month, year);
      filteredEvents.push({
        ...event,
        lengthInMonth,
      });
      eventLengthTotal += lengthInMonth;
    }
    return filteredEvents;
  }, []);

  const emptyDays = getLastDayOfMonth(month, year) - eventLengthTotal;

  if (emptyDays > 0) {
    const dummyEvent = {
      _id: 'dummy-event',
      isDummyEvent: true,
      lengthInMonth: emptyDays,
    };

    if (year === BEGINNING_OF_TIME.year && month === BEGINNING_OF_TIME.month) {
      dummyEvent.startDay = offset;
      calendarEvents.unshift(dummyEvent);

    } else {
      calendarEvents.push(dummyEvent);
    }
  }

  let filledWeekDays = offset;

  return calendarEvents.reduce((displayEvents, event) => {
    let { lengthInMonth: eventLength } = event;

    while (eventLength > 0) {
      const remainingWeekDays = WEEK_DAYS - filledWeekDays;
      const displayEventLength = Math.min(eventLength, remainingWeekDays);
      displayEvents.push({
        ...event,
        startDay: filledWeekDays,
        length: displayEventLength,
      });

      filledWeekDays += displayEventLength;
      filledWeekDays = filledWeekDays === WEEK_DAYS ? 0 : filledWeekDays;

      eventLength -= displayEventLength;
    }

    return displayEvents;
  }, []);
};
