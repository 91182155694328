import _get from 'lodash.get';
import { createSelector } from 'reselect';

import {
  getAllPhotosAsArray,
  getCurrentRoutePayload,
  getSelectedDate,
  getSelectedEvent,
  getSelectedPhoto,
} from '@/selectors';

export const getPhotosSortedByDate = createSelector(
  [
    getAllPhotosAsArray,
  ],
  photos => photos.reduce((acc, curr) => {
    const { year, month, day } = curr.date;
    const accYear = acc[year] || {};
    const accMonth = accYear[month] || {};
    const accDay = accMonth[day] || [];

    // eslint-disable-next-line no-param-reassign
    acc = {
      ...acc,
      [year]: {
        ...accYear,
        [month]: {
          ...accMonth,
          [day]: [
            ...accDay,
            curr,
          ],
        },
      },
    };
    return acc;
  }, {})
);

export const getDatesWithPhotos = createSelector(
  [
    getPhotosSortedByDate,
  ],
  photos => {
    const validDates = [];
    const years = Object.keys(photos).map(Number);

    years.forEach(year => {
      const months = Object.keys(photos[year]).map(Number);
      months.forEach(month => {
        const days = Object.keys(photos[year][month]).map(Number);
        days.forEach(day => {
          const date = { day, month, year };
          validDates.push(date);
        });
      });
    });

    validDates.sort((d1, d2) => {
      const date1 = new Date(d1.year, d1.month, d1.day);
      const date2 = new Date(d2.year, d2.month, d2.day);
      return date1 > date2 ? 1 : -1;
    });

    return validDates;
  }
);

export const getPhotosForSelectedDate = createSelector(
  [
    getSelectedDate,
    getPhotosSortedByDate,
  ],
  ({ year, month, day }, photos) => (
    _get(photos, `${year}.${month}.${day}`, [])
      .sort((p1, p2) => (p1.displayTS > p2.displayTS ? 1 : -1))
  )
);

export const getPhotosForSelectedEvent = createSelector(
  [
    getAllPhotosAsArray,
    getSelectedEvent,
  ],
  (photos, { start, end }) => {
    const startDate = new Date(start.year, start.month, start.day);
    const endDate = new Date(end.year, end.month, end.day);

    return photos
      .filter(photo => {
        const photoDate = new Date(photo.date.year, photo.date.month, photo.date.day);
        return photoDate >= startDate && photoDate <= endDate;
      })
      .sort((p1, p2) => (p1.displayTS > p2.displayTS ? 1 : -1));
  }
);

export const getSelectedPhotoWithNeighbours = createSelector(
  [
    getSelectedPhoto,
    getPhotosForSelectedDate,
    getPhotosForSelectedEvent,
    getCurrentRoutePayload,
  ],
  (selectedPhoto, datePhotos, eventPhotos, routePayload) => {
    if (!selectedPhoto._id) {
      return {};
    }

    let previousPhoto = null;
    let nextPhoto = null;
    const photoList = datePhotos.length ? datePhotos : eventPhotos;

    if (photoList.length > 1) {
      const currentIndex = photoList.findIndex(p => p._id === selectedPhoto._id);
      const previousIndex = currentIndex - 1 === -1 ? photoList.length - 1 : currentIndex - 1;
      const nextIndex = currentIndex + 1 === photoList.length ? 0 : currentIndex + 1;
      previousPhoto = {
        ...routePayload,
        photoId: photoList[previousIndex]._id,
      };
      nextPhoto = {
        ...routePayload,
        photoId: photoList[nextIndex]._id,
      };
    }

    return {
      selectedPhoto,
      previousPhoto,
      nextPhoto,
    };
  }
);
