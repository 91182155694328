import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '@/actions';
import { ALERT_LEVEL } from '@/constants/alerts';
import * as s from '@/selectors';

const mapStateToProps = state => ({
  ...s.getAlert(state),
});

const mapDispatchToProps = dispatch => ({
  onConfirm: () => dispatch(actions.clearAlert()),
});

const Alert = ({ level, text, onConfirm }) => {
  let title = '';
  if (level === ALERT_LEVEL.ERROR) {
    title = 'Oh no...';
  } else if (level === ALERT_LEVEL.VALIDATION) {
    title = "Something's amiss!";
  } else if (level === ALERT_LEVEL.INFO) {
    title = 'Great!';
  }
  return (
    <aside className='alert-wrapper overlay'>
      <div className='alert'>
        <h2>{title}</h2>
        <p>{text}</p>
        <button className='button' type='button' onClick={onConfirm}>OK</button>
      </div>
    </aside>
  );
};

Alert.propTypes = {
  level: PropTypes.string,
  text: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
};

Alert.defaultProps = {
  level: ALERT_LEVEL.ERROR,
  text: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
