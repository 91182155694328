import React from 'react';
import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';

const NavButton = ({
  buttonText,
  classNames,
  iconId,
  payload,
  type,
}) => (
  <Link
    className={classNames}
    to={{ type, payload }}
  >
    <span className={`nav-button__icon ${iconId}`} />
    <span className='nav-button__text'>{buttonText}</span>
  </Link>
);

NavButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  classNames: PropTypes.string.isRequired,
  iconId: PropTypes.string.isRequired,
  payload: PropTypes.object,
  type: PropTypes.string.isRequired,
};

NavButton.defaultProps = {
  payload: {},
};

export default NavButton;
