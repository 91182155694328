import moment from 'moment';

import * as actions from '@/actions';
import * as c from '@/constants/dataTypes';
import * as bounds from '@/constants/timeBounds';
import * as s from '@/selectors';

export const fetchOverviewData = dispatch => {
  dispatch(actions.fetchData(c.EVENTS));
  dispatch(actions.fetchData(c.OVERVIEW, {
    startYear: bounds.BEGINNING_OF_TIME.year,
    startMonth: bounds.BEGINNING_OF_TIME.month,
    endYear: bounds.END_OF_TIME.year,
    endMonth: bounds.END_OF_TIME.month,
  }));
};

export const monthToString = (value, key) => (key === 'month' ? moment().month(value).format('MMM') : value);

export const dateToNumber = (value, key) => {
  if (key === 'month') {
    return moment().month(value).month();
  } else if (key === 'year' || key === 'day') {
    return Number(value);
  }
  return value;
};

export const fetchMonthlyData = (dispatch, getState) => {
  const { selectedMonth, previousMonth, nextMonth } = s.getSelectedMonthWithNeighbours(getState());
  dispatch(actions.fetchData(c.EVENTS));
  [selectedMonth, previousMonth, nextMonth].forEach(month => {
    dispatch(actions.fetchData(c.COMMENTS, month));
    dispatch(actions.fetchData(c.PHOTOS, month));
    dispatch(actions.fetchData(c.TEXTS, month));
  });
};

export const fetchEventData = (dispatch, getState) => {
  const { eventId } = s.getCurrentRoutePayload(getState());
  if (eventId) {
    dispatch(actions.fetchData(c.EVENTS));
    dispatch(actions.fetchData(c.COMMENTS, { eventId }));
    dispatch(actions.fetchData(c.PHOTOS, { eventId }));
    dispatch(actions.fetchData(c.TEXTS, { eventId }));
  }
};

export const fetchMapData = dispatch => {
  dispatch(actions.fetchData(c.EVENTS));
  dispatch(actions.fetchData(c.COUNTRIES));
};

export const autoForwardAdmin = dispatch => {
  if (window.localStorage.getItem('token')) {
    dispatch(actions.redirectAdmin());
  }
};

export const authorizeAdmin = dispatch => {
  if (!window.localStorage.getItem('token')) {
    dispatch(actions.redirectLogin());
    return;
  }

  // Events are needed for creating texts
  dispatch(actions.fetchData(c.EVENTS));
};
