import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as s from '@/selectors';

import LoaderSvg from '@/components/svg/LoaderSvg';

const mapStateToProps = state => ({
  message: s.getLoaderMessage(state),
});

const Loader = ({ message }) => (
  <div className='overlay'>
    <LoaderSvg classNames='loader' />
    {message && <p className='loader__message'>{message}</p>}
  </div>
);

Loader.propTypes = {
  message: PropTypes.string,
};

Loader.defaultProps = {
  message: '',
};

export default connect(mapStateToProps)(Loader);
