import { combineReducers } from 'redux';

import { default as comment } from './comment';
import { default as deletePhoto } from './deletePhoto';
import { default as newsletter } from './newsletter';

export default combineReducers({
  comment,
  deletePhoto,
  newsletter,
});

export const getFormInput = (state, formName) => state.form[formName];
