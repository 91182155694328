const today = new Date();

const initialState = {
  year: today.getUTCFullYear(),
  month: today.getUTCMonth(),
  day: today.getUTCDate(),
};

export default (state = initialState) => state;

export const getToday = state => state.session.today;
