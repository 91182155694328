// FORMS
export const COMMENT = 'comment';
export const NEWSLETTER = 'newsletter';
export const DELETE_PHOTO = 'deletePhoto';

// INPUT FIELDS
export const CONTENT = 'content';
export const EMAIL = 'email';
export const NAME = 'name';
export const PHOTO_ID = 'photoId';
