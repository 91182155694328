import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { redirect } from 'redux-first-router';
import Link from 'redux-first-router-link';

import * as s from '@/selectors';

const mapStateToProps = state => ({
  link: s.getSlideshowBacklink(state),
  photo: s.getSelectedPhoto(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });

/* eslint-disable jsx-a11y/media-has-caption */
class Slideshow extends React.Component {
  constructor(props) {
    super(props);

    this.redirectBack = this.redirectBack.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.redirectBack);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.redirectBack);
  }

  redirectBack(e) {
    if (e.key === 'Escape') {
      this.props.dispatch(redirect(this.props.link));
    }
  }

  render() {
    const { link, photo } = this.props;
    return (
      <Link className='slideshow' to={link}>
        {photo.resourceType === 'video'
          ? (
            <div className='slideshow__media'>
              <video className='slideshow__video' controls src={photo.url} />
            </div>
          ) : <div className='slideshow__media' style={{ backgroundImage: `url(${photo.url})` }} />
        }
        <span className='slideshow__close far fa-times-circle' />
      </Link>
    );
  }
}

Slideshow.propTypes = {
  dispatch: PropTypes.func,
  link: PropTypes.object.isRequired,
  photo: PropTypes.object,
};

Slideshow.defaultProps = {
  dispatch: () => null,
  photo: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Slideshow);
