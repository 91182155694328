import { createSelector } from 'reselect';

import { getSelectedEvent } from './location';
import {
  getAllTextsAsArray,
  getSelectedDate,
} from '@/selectors';
import * as utils from '@/utils';

export const getTextForSelectedDate = createSelector(
  [
    getAllTextsAsArray,
    getSelectedDate,
  ],
  (texts, date) => {
    const text = texts.find(t => utils.matchesDate(t.date, date));

    if (!text) {
      return null;
    }

    return text;
  }
);

export const getTextForSelectedEvent = createSelector(
  [
    getAllTextsAsArray,
    getSelectedEvent,
  ],
  (texts, event) => {
    const text = texts.find(t => t.eventId === event._id);

    if (!text) {
      return null;
    }

    return text;
  }
);
