import * as t from '@/actions/types';

const initialState = '';

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  if (type === t.SELECT_TEXT_EVENT) {
    return payload.eventId;
  }

  return state;
};

export const getSelectedTextEvent = state => state.admin.selectedEvent;
