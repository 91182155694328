import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';

import { MONTH } from '@/constants/routes';
import { getPhotoUrl } from '@/utils/cloudinary';

const OverviewMonth = ({
  children,
  date,
  photo,
  title,
}) => {
  const linkTo = useMemo(() => ({ type: MONTH, payload: date }), [MONTH, date]);

  return (
    <div className='ov-month'>
      <header className='ov-month__header'>
        <Link
          className='ov-month__link'
          to={linkTo}
        >
          {title}
          <div
            alt='Teaser'
            className='ov-month__image'
            style={{ backgroundImage: `url(${getPhotoUrl(photo, 'medium')})` }}
          />
        </Link>
      </header>
      <div className='ov-grid-wrapper'>
        {children}
      </div>
    </div>
  );
};

OverviewMonth.propTypes = {
  children: PropTypes.node.isRequired,
  date: PropTypes.object.isRequired,
  photo: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default OverviewMonth;
