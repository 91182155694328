import * as c from '@/constants/dataTypes';
import * as k from '@/constants/keywords';
import * as t from '@/actions/types';

const initialState = {};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  if (
    type === t.SEND_REQUEST
    && payload.dataType === c.TEXTS
    && (payload.requestData.type === k.ALL || payload.method === 'post')
  ) {
    return {
      ...state,
      ...payload.responseData,
    };
  }

  return state;
};

export const getAdminTexts = state => state.admin.texts;
export const getAdminTextsArray = state => Object.values(state.admin.texts);
