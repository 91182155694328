import moment from 'moment';

import {
  BEGINNING_OF_TIME,
  END_OF_TIME,
} from '@/constants/timeBounds';

export const getDisplayDate = date => moment(date).format('D MMM YYYY');

export const getDisplayDateWithTime = timestamp => moment(timestamp).format('D MMM YYYY, kk:mm');

export const getDisplayDateWithWeekday = date => moment(date).format('dddd, MMMM D');

export const getDisplayMonth = date => moment(date).format('MMMM YYYY');

export const getLastDayOfMonth = (month, year) => (new Date(year, month + 1, 0)).getDate();

export const getMonthTrio = (month, year) => ({
  current: { month, year },
  next: {
    month: month === 11 ? 0 : month + 1,
    year: month === 11 ? year + 1 : year,
  },
  previous: {
    month: month === 0 ? 11 : month - 1,
    year: month === 0 ? year - 1 : year,
  },
});

export const locateInTime = (start, end, comparisonDate) => {
  const date = comparisonDate && comparisonDate.day
    ? new Date(comparisonDate.year, comparisonDate.month, comparisonDate.day)
    : new Date();
  const startDate = new Date(start.year, start.month, start.day);
  const endDate = new Date(end.year, end.month, end.day);

  if (startDate <= date && endDate >= date) {
    return 'current';
  }

  if (startDate < date && endDate < date) {
    return 'past';
  }

  if (startDate > date && endDate > date) {
    return 'future';
  }

  return null;
};

export const matchesDate = (date, comparisonDate) => {
  if (!date) {
    return false;
  }

  return date.year === comparisonDate.year
    && date.month === comparisonDate.month
    && date.day === comparisonDate.day;
};

export const compareMonths = (date, comparisonDate) => {
  const { year, month } = date;
  return moment({ year, month }).diff(moment(comparisonDate), 'months');
};

export const getWeekOffset = (month, year) => {
  const date = new Date(year, month, 1);
  const firstWeekday = date.getDay();

  // use zero based week days with week start set to Monday
  return firstWeekday === 0 ? 6 : firstWeekday - 1;
};

export const getCalendarDaysArray = (month, year) => {
  const numberOfDays = getLastDayOfMonth(month, year);
  const daysArray = [];

  // eslint-disable-next-line no-plusplus
  for (let day = 1; day <= numberOfDays; day++) {
    const date = { year, month, day };
    daysArray.push({ date });
  }
  return daysArray;
};

export const getAllCalendarMonths = () => {
  const startDate = moment(BEGINNING_OF_TIME);
  const endDate = moment(END_OF_TIME);
  const endMonth = endDate.format('M');
  const it = startDate.clone();

  const months = [];

  // eslint-disable-next-line no-unmodified-loop-condition
  while (endDate > it || it.format('M') === endMonth) {
    months.push({ month: it.month(), year: it.year() });
    it.add(1, 'month');
  }

  return months;
};
