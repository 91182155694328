import React from 'react';

import Calendar from '@/components/month/Calendar';
import Header from '@/components/global/Header';
import Navigation from '@/components/global/Navigation';

const Month = () => [
  <Header key='header' />,
  <Calendar key='calendar' />,
  <Navigation key='navigation' />,
];

export default Month;
