import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';

import * as actions from '@/actions';
import * as s from '@/selectors';
import * as c from '@/constants/formNames';

const mapStateToProps = state => ({
  inputValues: s.getFormInput(state, c.COMMENT),
});

const mapDispatchToProps = dispatch => ({
  onChangeValue: (key, value) => dispatch(actions.onChangeFormValue(c.COMMENT, key, value)),
  onSubmit: () => dispatch(actions.sendComment()),
});

const Comments = ({
  inputValues,
  onChangeValue,
  onSubmit,
}) => (
  <form className='comment comment__form'>
    <div className='comment__info'>
      <label htmlFor={c.NAME}>Name:</label>
      <input
        className='input'
        id={c.NAME}
        placeholder='Your Name'
        type='text'
        value={inputValues.name}
        onChange={e => onChangeValue(c.NAME, e.target.value)}
      />
      <label htmlFor={c.EMAIL}>Email:</label>
      <input
        className='input'
        id={c.EMAIL}
        placeholder='your@email.com'
        type='email'
        value={inputValues.email}
        onChange={e => onChangeValue(c.EMAIL, e.target.value)}
      />
      <p className='comment__hint'>We will not share this.</p>
      <button
        className='button button--dark'
        type='button'
        onClick={onSubmit}
      >
        Send
      </button>
    </div>
    <div className='comment__text'>
      <ReactQuill
        className='comment__editor'
        modules={{
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'link'],
              // TODO: add emojis
            ],
          },
        }}
        placeholder='Write your comment here (gern auch auf Deutsch eller på svenska!)'
        theme='snow'
        value={inputValues.content}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={value => onChangeValue(c.CONTENT, value)}
      />
    </div>
  </form>
);

Comments.propTypes = {
  inputValues: PropTypes.object.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
