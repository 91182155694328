import { createSelector } from 'reselect';

import {
  getLoadingState,
  getCurrentRoutePayload,
} from '@/selectors';

export const displayLoadingState = createSelector(
  [
    getLoadingState,
    getCurrentRoutePayload,
  ],
  (loadingState, { month, year, eventId }) => {
    let isLoading = loadingState.generic > 0;

    if (month != null && year != null) {
      isLoading = isLoading || loadingState.date[year][month] > 0;

    } else if (eventId != null) {
      isLoading = isLoading || loadingState.event[eventId] > 0;
    }

    return isLoading;
  }
);
