import React from 'react';
import PropTypes from 'prop-types';

import Comments from '@/components/global/Comments';
import PhotoGridItem from './PhotoGridItem';
import PhotoGridText from './PhotoGridText';

const PhotoGrid = ({ isScrollable, photos, text }) => (
  <section
    className='content-wrapper content-wrapper--scroll'
    id='main'
    role='main'
    style={{ overflowY: isScrollable ? 'scroll' : 'hidden' }}
  >
    <section className='photo-grid'>
      {text && <PhotoGridText text={text} />}
      {photos.map(photo => <PhotoGridItem key={photo._id} photo={photo} />)}
    </section>
    <Comments />
  </section>
);

PhotoGrid.propTypes = {
  isScrollable: PropTypes.bool,
  photos: PropTypes.array.isRequired,
  text: PropTypes.object,
};

PhotoGrid.defaultProps = {
  isScrollable: true,
  text: null,
};

export default PhotoGrid;
