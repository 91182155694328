import axios from 'axios';

export default async function ajax(url, method = 'get', data, token, headers) {
  const config = {
    url,
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  };

  if (method === 'get') {
    config.params = data;
  } else {
    config.data = data;
  }

  if (token) {
    config.headers.token = token;
  }


  const response = await axios(config);
  return [response.data, response];
}
