import { createSelector } from 'reselect';

import {
  getAllCountriesAsArray,
  getSortedEvents,
  getCurrentEvent,
} from '@/selectors';
import * as utils from '@/utils';

export const getCountriesForMap = createSelector(
  [
    getAllCountriesAsArray,
    getSortedEvents,
    getCurrentEvent,
  ],
  (countries, events, currentEvent = {}) => {
    const today = new Date();
    return countries.map(country => {
      if (!country._id) {
        return {};
      }

      if (country.eventIds.includes(currentEvent._id)) {
        return {
          ...country,
          isCurrent: true,
        };
      }

      const lastEventInCountry = events.filter(e => country.eventIds.includes(e._id)).pop();

      if (lastEventInCountry) {
        const eventDate = new Date(
          lastEventInCountry.start.year,
          lastEventInCountry.start.month,
          lastEventInCountry.start.day
        );

        if (eventDate > today) {
          return {
            ...country,
            isFuture: true,
          };
        }
      }

      return country;
    });
  }
);

export const getCitiesForMap = createSelector(
  [
    getSortedEvents,
  ],
  events => events.reduce((acc, event) => {
    const displayEvent = {
      _id: event._id,
      title: `${utils.getDisplayDate(event.start)} – ${utils.getDisplayDate(event.end)}`,
      era: utils.locateInTime(event.start, event.end),
    };

    const city = acc.find(c => c.name === event.summary);

    if (city) {
      city.events[displayEvent.era].push(displayEvent);

      if (city.era !== 'current') {
        city.era = displayEvent.era;
      }

    } else {
      acc.push({
        name: event.summary,
        location: event.location,
        era: displayEvent.era,
        events: {
          current: [],
          past: [],
          future: [],
          [displayEvent.era]: [displayEvent],
        },
      });
    }

    return acc;
  }, [])
);
