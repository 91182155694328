import * as t from '@/actions/types';

const initialState = {};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  if (type === t.ADD_ALERT) {
    return {
      ...state,
      ...payload,
    };
  }

  if (type === t.CLEAR_ALERT) {
    return initialState;
  }

  return state;
};

export const getAlert = state => state.ui.alert;
export const hasAlert = state => Object.keys(state.ui.alert).length > 0;
