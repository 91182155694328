import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '@/actions';
import * as selectors from '@/selectors';

const mapStateToProps = state => ({
  isSynced: selectors.isCountriesSynced(state),
  syncedCountries: selectors.getSyncedCountries(state),
});

const mapDispatchToProps = dispatch => ({
  onSync: () => {
    dispatch(actions.clearWsProgress());
    dispatch(actions.syncCountries());
  },
});

const Countries = ({
  isSynced,
  syncedCountries,
  onSync,
}) => [
  <button
    key='sync-button'
    className='button'
    disabled={isSynced}
    type='button'
    onClick={onSync}
  >
    Sync Countries
  </button>,
  isSynced && <p key='success'>{syncedCountries} synced.</p>,
];

Countries.propTypes = {
  isSynced: PropTypes.bool,
  syncedCountries: PropTypes.number,
  onSync: PropTypes.func,
};

Countries.defaultProps = {
  isSynced: false,
  syncedCountries: null,
  onSync: () => null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Countries);
