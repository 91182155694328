import * as actions from '@/actions';
import * as t from '@/actions/types';
import { PHOTO_ALBUMS } from '@/constants/routes';

export default ({ dispatch }) => next => action => {
  const { type, payload } = action;

  if (type === t.SEND_REQUEST && payload.url === PHOTO_ALBUMS) {
    const { albums = [], nextPageToken } = payload.responseData;

    payload.responseData = albums.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});

    if (nextPageToken) {
      dispatch(actions.fetchPhotoAlbums(nextPageToken));
    }
  }

  next(action);
};
