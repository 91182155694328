import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '@/actions';
import * as s from '@/selectors';
import * as c from '@/constants/formNames';

const mapStateToProps = state => ({
  inputValues: s.getFormInput(state, c.DELETE_PHOTO),
});

const mapDispatchToProps = dispatch => ({
  onChangeValue: (key, value) => dispatch(actions.onChangeFormValue(c.DELETE_PHOTO, key, value)),
  onSubmit: () => dispatch(actions.deletePhoto()),
});

const DeletePhotos = ({
  inputValues,
  onChangeValue,
  onSubmit,
}) => (
  <form className='admin__row'>
    <div>
      <label htmlFor={c.PHOTO_ID}>Photo ID:</label>
      <input
        className='input'
        id={c.PHOTO_ID}
        placeholder='cloudId'
        type='text'
        value={inputValues.photoId}
        onChange={e => onChangeValue(c.PHOTO_ID, e.target.value)}
      />
    </div>
    <button
      className='button'
      type='button'
      onClick={onSubmit}
    >
      Delete Photo
    </button>
  </form>
);

DeletePhotos.propTypes = {
  inputValues: PropTypes.object.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletePhotos);
