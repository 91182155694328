import * as actions from '@/actions';
import * as t from '@/actions/types';

export default ({ dispatch }) => next => action => {
  const { type, payload } = action;

  if (type === t.LOGIN_USER) {
    window.localStorage.setItem('token', payload.token);
    next(action);
    if (payload.forward) {
      dispatch(actions.redirectAdmin());
    }
    return;
  }

  if (type === t.LOGOUT_USER) {
    window.localStorage.removeItem('token');
    document.cookie = `googleAuthToken=null;expires=${new Date(0)}`;
    next(action);
    dispatch(actions.redirectLogin());
    return;
  }

  if (type === t.INITIALIZE_APP) {
    const token = window.localStorage.getItem('token');

    if (token) {
      dispatch(actions.loginUser(token, false));
    }
  }

  next(action);
};
