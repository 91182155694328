import { clearForm } from '@/actions/form/creators';
import { showAlert } from '@/actions/ui/creators';
import {
  ALERT_LEVEL,
  ERRORS,
  MESSAGES,
  VALIDATION,
} from '@/constants/alerts';
import * as c from '@/constants/formNames';
import * as s from '@/selectors';
import * as t from './types';

export const dataFetched = (dataType, query, responseData) => ({
  type: t.DATA_FETCHED,
  payload: {
    dataType,
    query,
    responseData,
  },
});

export const fetchData = (dataType, query) => ({
  type: t.SEND_REQUEST,
  payload: {
    dataType,
    requestData: query,
    successFns: [
      (dispatch, responseData) => dispatch(dataFetched(dataType, query, responseData)),
    ],
  },
});

export const sendComment = () => (dispatch, getState) => {
  const commentInput = s.getFormInput(getState(), c.COMMENT);
  const routePayload = s.getCurrentRoutePayload(getState());
  const requestData = { ...commentInput };

  if (!commentInput.name) {
    return dispatch(showAlert(VALIDATION.NAME, ALERT_LEVEL.VALIDATION));
  }

  if (!commentInput.email) {
    return dispatch(showAlert(VALIDATION.EMAIL, ALERT_LEVEL.VALIDATION));
  }

  if (!commentInput.content) {
    return dispatch(showAlert(VALIDATION.MESSAGE, ALERT_LEVEL.VALIDATION));
  }

  if (routePayload.eventId) {
    requestData.eventId = routePayload.eventId;
  } else {
    requestData.date = routePayload;
  }

  return dispatch({
    type: t.SEND_REQUEST,
    payload: {
      dataType: 'comments',
      method: 'post',
      requestData,
      successFns: [
        () => dispatch(clearForm(c.COMMENT)),
        (_, responseData) => dispatch(dataFetched('comments', requestData.date, requestData.eventId, responseData)),
      ],
      errorFns: [
        () => dispatch(showAlert(ERRORS.COULD_NOT_SAVE_COMMENT)),
      ],
    },
  });
};

export const subscribeToNewsletter = () => (dispatch, getState) => {
  const { name, email } = s.getFormInput(getState(), c.NEWSLETTER);

  if (!name) {
    return dispatch(showAlert(VALIDATION.NAME, ALERT_LEVEL.VALIDATION));
  }

  if (!email) {
    return dispatch(showAlert(VALIDATION.EMAIL, ALERT_LEVEL.VALIDATION));
  }

  return dispatch({
    type: t.SEND_REQUEST,
    payload: {
      dataType: 'subscribe',
      method: 'post',
      requestData: { name, email },
      successFns: [
        () => dispatch(clearForm(c.NEWSLETTER)),
        (_, response) => {
          if (response.exists) {
            dispatch(showAlert(MESSAGES.ALREADY_SUBSCRIBED, ALERT_LEVEL.INFO));
          } else {
            dispatch(showAlert(MESSAGES.SUBSCRIBED_SUCCESS, ALERT_LEVEL.INFO));
          }
        },
      ],
      errorFns: [
        () => dispatch(showAlert(ERRORS.COULD_NOT_SUBSCRIBE)),
      ],
    },
  });
};
