const cloudinaryUrl = 'https://res.cloudinary.com/photo-calendar';
const type = 'upload';

const transformations = {
  small: 'w_200,h_130,c_lfill,q_90',
  medium: 'w_700,h_500,c_fit,q_90',
};

export const getPhotoUrl = (photo, size = 'small') => {
  if (!photo) {
    return '';
  }

  const { cloudId, resourceType } = photo;
  const thumbnailTransformations = transformations[size];

  return `${cloudinaryUrl}/${resourceType}/${type}/${thumbnailTransformations}/${cloudId}.jpg`;
};
