import * as t from '@/actions/types';

const initialState = '';

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  if (type === t.LOGIN_USER) {
    return payload.token;
  }

  if (type === t.LOGOUT_USER) {
    return initialState;
  }

  return state;
};

export const getSessionToken = state => state.session.appToken;
