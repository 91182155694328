import * as c from '@/constants/dataTypes';
import * as t from '@/actions/types';

const initialState = {};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  if (type === t.DATA_FETCHED && payload.dataType === c.COMMENTS) {
    return {
      ...state,
      ...payload.responseData,
    };
  }

  return state;
};

export const getAllComments = state => state.data.comments;
export const getAllCommentsAsArray = state => Object.values(state.data.comments);
