import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { DAY_PHOTO } from '@/constants/routes';
import * as s from '@/selectors';

import Header from '@/components/global/Header';
import Navigation from '@/components/global/Navigation';
import PhotoGrid from '@/components/global/PhotoGrid';
import Slideshow from '@/components/global/Slideshow';

const mapStateToProps = state => ({
  isPhotoRoute: s.getCurrentRouteType(state) === DAY_PHOTO,
  photos: s.getPhotosForSelectedDate(state),
  text: s.getTextForSelectedDate(state),
});

const Day = ({
  isPhotoRoute,
  photos,
  text,
}) => [
  <Header key='header' />,
  <PhotoGrid key='photo-grid' isScrollable={!isPhotoRoute} photos={photos} text={text} />,
  <Navigation key='navigation' />,
  isPhotoRoute && <Slideshow key='slideshow' />,
];

Day.propTypes = {
  isPhotoRoute: PropTypes.bool.isRequired,
  photos: PropTypes.array,
  selectedPhotoId: PropTypes.string,
  text: PropTypes.object,
};

Day.defaultProps = {
  isPhotoRoute: false,
  photos: [],
  selectedPhotoId: '',
  text: null,
};

export default connect(mapStateToProps)(Day);
