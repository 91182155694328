import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '@/actions';
import { GOOGLE_AUTH } from '@/constants/routes';
import { readCookie } from '@/utils/cookies';

import Header from '@/components/global/Header';
import AdminSection from '@/components/admin/AdminSection';
import Countries from '@/components/admin/Countries';
import DeletePhotos from '@/components/admin/DeletePhotos';
import Events from '@/components/admin/Events';
import Photos from '@/components/admin/Photos';
import Status from '@/components/admin/Status';
import Texts from '@/components/admin/Texts';

const mapDispatchToProps = dispatch => ({
  onAuth: token => dispatch(actions.setGoogleToken(token)),
  onMountAdmin: () => {
    dispatch(actions.connectToWebsocket());
    dispatch(actions.fetchAllTexts());
  },
});

class Admin extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onWindowFocus = this.onWindowFocus.bind(this);
  }

  componentDidMount() {
    this.props.onMountAdmin();
    if (window.location.protocol.includes('https')) {
      window.open(GOOGLE_AUTH);
      window.addEventListener('focus', this.onWindowFocus);
    }
  }

  onWindowFocus() {
    const googleToken = readCookie('googleAuthToken');
    if (googleToken) {
      this.props.onAuth(window.decodeURIComponent(googleToken));
      window.removeEventListener('focus', this.onWindowFocus);
    }
  }

  render() {
    return [
      <Header key='header' />,
      <section
        key='admin'
        className='content-wrapper content-wrapper--narrow'
        id='main'
        role='main'
      >
        <AdminSection title='Photos'>
          <Photos />
        </AdminSection>
        <AdminSection title='Delete Photos'>
          <DeletePhotos />
        </AdminSection>
        <AdminSection title='Events and Countries'>
          <div className='admin__row'>
            <Events />
            <Countries />
          </div>
        </AdminSection>
        <AdminSection title='Sync Status'>
          <Status />
        </AdminSection>
        <AdminSection title='Texts'>
          <Texts />
        </AdminSection>
      </section>,
    ];
  }
}

Admin.propTypes = {
  onAuth: PropTypes.func,
  onMountAdmin: PropTypes.func,
};

Admin.defaultProps = {
  onAuth: () => null,
  onMountAdmin: () => null,
};

export default connect(null, mapDispatchToProps)(Admin);
