import { createSelector } from 'reselect';

import { getDatesWithPhotos } from './photos';
import { getSelectedDate } from '@/reducers/selectors';
import * as utils from '@/utils';

export const getSelectedDayWithNeighbours = createSelector(
  [
    getSelectedDate,
    getDatesWithPhotos,
  ],
  (selectedDay, dates) => {

    const selectedIndex = dates.findIndex(date => utils.matchesDate(date, selectedDay));
    const previousDay = dates[selectedIndex - 1];
    const nextDay = dates[selectedIndex + 1];

    return {
      selectedDay,
      previousDay,
      nextDay,
    };
  }
);
