import * as t from '@/actions/types';
import * as c from '@/constants/formNames';

const initialState = {
  name: '',
  email: '',
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  if (type === t.CHANGE_FORM_VALUE && payload.form === c.NEWSLETTER) {
    return {
      ...state,
      [payload.key]: payload.value,
    };
  }

  if (type === t.CLEAR_FORM && payload.form === c.NEWSLETTER) {
    return initialState;
  }

  return state;
};
