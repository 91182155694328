import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '@/actions';
import * as k from '@/constants/keywords';
import * as selectors from '@/selectors';

import DatePicker from './DatePicker';
import EventPicker from './EventPicker';
import TextTabBar from './TextTabBar';
import TextField from './TextField';

const mapStateToProps = state => ({
  currentText: selectors.getCurrentAdminText(state),
  selectedDate: selectors.getSelectedTextDate(state),
  selectedEvent: selectors.getSelectedTextEvent(state),
  texts: selectors.getFilteredAdminTexts(state),
  textType: selectors.getActiveTextTab(state),
});

const mapDispatchToProps = dispatch => ({
  onSave: (...values) => dispatch(actions.saveText(...values)),
});

class Texts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textValue: '',
    };
    this.onChangeText = this.onChangeText.bind(this);
  }

  onChangeText(textValue) {
    this.setState({ textValue });
  }

  render() {
    const {
      currentText,
      selectedDate,
      selectedEvent,
      texts,
      textType,
      onSave,
    } = this.props;

    const isTextWithDate = textType === k.DATE || textType === k.NEWSLETTER;

    return [
      <TextTabBar key='tab-bar' />,
      <p
        key='text-info'
        className='admin__row admin__row--small'
      >
        We have {texts.length} {textType} texts
      </p>,

      isTextWithDate ? (
        <DatePicker key='date-picker' selectedDate={selectedDate} />
      ) : (
        <EventPicker key='event-picker' />
      ),

      <TextField
        key='text-field'
        text={currentText}
        onChangeText={this.onChangeText}
      />,
      <div
        key='submit-button'
        className='admin__row'
      >
        <button
          className='button'
          disabled={(isTextWithDate && !selectedDate.year) || (!isTextWithDate && !selectedEvent)}
          type='button'
          onClick={() => onSave(textType, selectedDate, selectedEvent, this.state.textValue)}
        >
          Save
        </button>
      </div>,
    ];
  }
}

Texts.propTypes = {
  currentText: PropTypes.object,
  selectedDate: PropTypes.object,
  selectedEvent: PropTypes.string,
  texts: PropTypes.array,
  textType: PropTypes.string,
  onSave: PropTypes.func,
};

Texts.defaultProps = {
  currentText: {},
  selectedDate: {},
  selectedEvent: '',
  texts: [],
  textType: k.DATE,
  onSave: () => null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Texts);
