export const styleCountry = country => {
  const styles = {
    color: '#249887', // $color-light
    fillColor: '#249887', // $color-light
    className: 'country-polygon',
  };

  if (country.isCurrent) {
    styles.color = '#f28100'; // color-accent-orange
    styles.fillColor = '#f28100'; // color-accent-orange

  } else if (country.isFuture) {
    styles.color = '#a33f9c'; // color-accent-violet
    styles.fillColor = '#a33f9c'; // color-accent-violet
  }

  return styles;
};
