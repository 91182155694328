import React from 'react';
import PropTypes from 'prop-types';
import { GeoJSON } from 'react-leaflet';

import { styleCountry } from '@/utils/map';

const Country = ({ country }) => (
  <GeoJSON
    data={country.geoJson}
    style={styleCountry(country)}
  />
);

Country.propTypes = {
  country: PropTypes.object.isRequired,
};

export default Country;
