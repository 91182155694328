import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Link from 'redux-first-router-link';

import * as s from '@/selectors';

const mapStateToProps = state => ({
  ...s.getHeaderProps(state),
});

const Header = ({
  numberOfComments,
  links,
  subtitle,
  title,
}) => (
  <header className='header'>
    <section className={`header__titles${links.length > 0 ? ' header__titles--borders' : ''}`}>
      <h1 className='title'>
        {title}
      </h1>
      {subtitle && <p className='subtitle'>{subtitle}</p>}
    </section>
    {links.map(link => (
      <Link
        key={link.type}
        className='header__icon'
        style={{ order: link.order }}
        to={{ type: link.type, payload: link.payload }}
      >
        <span className={link.iconId} />
      </Link>
    ))}
    {numberOfComments > 0 && (
      <a
        className='header__icon'
        href={`${window.location.href}#comments`}
        style={{ order: 4 }}
      >
        <span className='far fa-comment' />
        <span className='header__comment-number'>{numberOfComments}</span>
      </a>
    )}
  </header>
);

Header.propTypes = {
  links: PropTypes.array,
  numberOfComments: PropTypes.number,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

Header.defaultProps = {
  links: [],
  numberOfComments: 0,
  subtitle: '',
};

export default connect(mapStateToProps)(Header);
