import React from 'react';

import text from '@/constants/infoText';

import Header from '@/components/global/Header';
import NewsletterForm from '@/components/global/NewsletterForm';

const INFO_IMAGE = 'https://res.cloudinary.com/photo-calendar/image/upload/w_500,h_500,c_fill/gx7thxzz09kmvpnboykj.jpg';

const Info = () => [
  <Header key='header' />,
  <section key='info' className='content-wrapper info' id='main' role='main'>
    <section className='text info__text'>
      <h2>Hello there...</h2>
      {text.map(paragraph => <p key={paragraph.substring(0, 10)}>{paragraph}</p>)}
    </section>
    <section className='info__sidebar'>
      <div className='info__image' style={{ backgroundImage: `url(${INFO_IMAGE})` }} />
      <NewsletterForm />
    </section>
  </section>,
];

export default Info;
