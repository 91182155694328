import React from 'react';
import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';

import { EVENT } from '@/constants/routes';

const CalendarEvent = ({
  _id,
  colorId,
  focussed,
  isDummyEvent,
  style,
  summary,
  onFocusEvent,
}) => (
  isDummyEvent
    ? (
      <div className='calendar__event calendar__event--dummy' style={style} />
    ) : (
      <Link
        className={`calendar__event calendar__event--${colorId}${focussed ? ' calendar__event--focussed' : ''}`}
        style={style}
        to={{ type: EVENT, payload: { eventId: _id } }}
        /* eslint-disable react/jsx-no-bind */
        onMouseEnter={() => onFocusEvent(_id)}
        onMouseLeave={() => onFocusEvent(null)}
        /* eslint-enable react/jsx-no-bind */
      >
        {summary}
      </Link>
    )
);

CalendarEvent.propTypes = {
  _id: PropTypes.string.isRequired,
  colorId: PropTypes.number,
  focussed: PropTypes.bool,
  isDummyEvent: PropTypes.bool,
  style: PropTypes.object,
  summary: PropTypes.string,
  onFocusEvent: PropTypes.func.isRequired,
};

CalendarEvent.defaultProps = {
  colorId: 2,
  focussed: false,
  isDummyEvent: false,
  style: {},
  summary: '',
};

export default CalendarEvent;
