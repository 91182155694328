import React from 'react';

import DayGrid from './DayGrid';
import EventGrid from './EventGrid';

const Calendar = () => (
  <section className='content-wrapper'>
    <DayGrid key='day-grid' />
    <EventGrid key='event-grid' />
  </section>
);

export default Calendar;
