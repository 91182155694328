const API_ADDRESS = process.env.REACT_APP_API_URL || window.location.origin;

export const ADMIN = 'routes/ADMIN';
export const DAY = 'routes/DAY';
export const DAY_PHOTO = 'routes/DAY_PHOTO';
export const EVENT = 'routes/EVENT';
export const EVENT_PHOTO = 'routes/EVENT_PHOTO';
export const INDEX = 'routes/INDEX';
export const INFO = 'routes/INFO';
export const LOGIN = 'routes/LOGIN';
export const MAP = 'routes/MAP';
export const MONTH = 'routes/MONTH';

export const GOOGLE_AUTH = `${API_ADDRESS}/api/v2/auth/google`;
export const PHOTO_ALBUMS = 'https://photoslibrary.googleapis.com/v1/albums';

export const SYNC_COUNTRIES = 'countries/sync';
export const SYNC_EVENTS = 'events/sync';
export const SYNC_PHOTOS = 'photos/sync';
export const PHOTOS = 'photos';
