import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Map,
  TileLayer,
} from 'react-leaflet';

import {
  mapOptions,
  MAX_BOUNDS_HORIZONTAL,
  tileOptions,
  ZOOM_DELTA_INIT,
} from '@/constants/map';
import * as s from '@/selectors';

import Header from '@/components/global/Header';
import Country from '@/components/map/Country';
import CityMarker from '@/components/map/CityMarker';

const mapStateToProps = state => ({
  countries: s.getCountriesForMap(state),
  cities: s.getCitiesForMap(state),
});

class MapView extends React.Component {
  constructor(props) {
    super(props);

    this.mapElement = React.createRef();
    this.adjustBounds = this.adjustBounds.bind(this);
  }

  componentDidMount() {
    this.adjustBounds();
  }

  adjustBounds() {
    const { leafletElement } = this.mapElement.current || {};
    if (leafletElement) {
      const { _northEast, _southWest } = leafletElement.getBounds();

      if (_northEast.lng > MAX_BOUNDS_HORIZONTAL.EAST || _southWest.lng < MAX_BOUNDS_HORIZONTAL.WEST) {
        leafletElement.zoomIn(ZOOM_DELTA_INIT, { animate: false });
        this.adjustBounds();

      } else {
        // Wrap minZoom setter in timeout to prevent loading empty tiles
        setTimeout(() => {
          leafletElement.setMinZoom(leafletElement.getZoom());
        }, 100);
      }
    }
  }

  render() {
    const { countries, cities } = this.props;
    return [
      <Header key='header' />,
      <div key='map' className='content-wrapper'>
        <Map ref={this.mapElement} {...mapOptions}>
          <TileLayer {...tileOptions} />
          {countries.map(country => <Country key={country._id} country={country} />)}
          {cities.map(city => <CityMarker key={city.name} city={city} />)}
        </Map>
      </div>,
    ];
  }
}

MapView.propTypes = {
  countries: PropTypes.array,
};

MapView.defaultProps = {
  countries: [],
};

export default connect(mapStateToProps)(MapView);
