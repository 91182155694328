import React from 'react';
import PropTypes from 'prop-types';
import {
  Marker,
  Popup,
} from 'react-leaflet';

import PopupSection from '@/components/map/PopupSection';

const CityMarker = ({ city }) => (
  <Marker
    icon={window.L.divIcon({
      className: '', // do not remove – overrides Leaflet default style
      iconSize: [30, 30],
      html: `<div class='city-marker city-marker--${city.era}' />`,
    })}
    position={[city.location.lat, city.location.lon]}
    title={city.name}
  >
    <Popup>
      <h2>{city.name}</h2>
      <PopupSection
        events={city.events.current}
        title="We're here now!"
        type='current'
      />
      <PopupSection
        events={city.events.past}
        title={`We've been here ${city.events.past.length === 1 ? 'once:' : `${city.events.past.length} times:`}`}
        type='past'
      />
      <PopupSection
        events={city.events.future}
        title='We will visit soon:'
        type='future'
      />
    </Popup>
  </Marker>
);

CityMarker.propTypes = {
  city: PropTypes.object.isRequired,
};

export default CityMarker;
