import React from 'react';
import PropTypes from 'prop-types';

import * as utils from '@/utils';

let MIN_COLUMNS, MAX_COLUMNS;

class PhotoGridText extends React.PureComponent {
  constructor(props) {
    super(props);

    const { min, max } = utils.calculateMaxColumns();
    MIN_COLUMNS = min;
    MAX_COLUMNS = max;

    this.state = {
      rowSpan: 2,
      columnSpan: MIN_COLUMNS,
    };

    this.container = React.createRef();
    this.increaseContainerSpan = this.increaseContainerSpan.bind(this);
  }

  componentDidMount() {
    this.increaseContainerSpan();
  }

  componentDidUpdate(prevProps) {
    if (this.props.text !== prevProps.text) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        rowSpan: 2,
        columnSpan: MIN_COLUMNS,
      }, this.increaseContainerSpan);
    }
  }

  increaseContainerSpan() {
    if (!utils.contentFitsContainer(this.container.current)) {
      this.setState(state => ({
        ...utils.getIncreasedContainerSpan(state, MAX_COLUMNS),
      }), this.increaseContainerSpan);
    }
  }

  render() {
    const { text } = this.props;
    return (
      <div
        ref={this.container}
        className='photo-grid__text'
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: text.content }}
        style={{
          gridRowEnd: `span ${this.state.rowSpan}`,
          gridColumnEnd: `span ${this.state.columnSpan}`,
        }}
      />
    );
  }
}

PhotoGridText.propTypes = {
  text: PropTypes.object.isRequired,
};

export default PhotoGridText;
