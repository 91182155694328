import { getLastDayOfMonth } from '@/utils';

export const fitEventToMonth = (event, month, year) => {
  const lastDayOfMonth = getLastDayOfMonth(month, year);
  const start = event.start.month === month ? event.start.day : 1;
  const end = event.end.month === month ? event.end.day : lastDayOfMonth;

  // add 1 to reflect inclusive end date
  return (end - start) + 1;
};
