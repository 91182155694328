import { createSelector } from 'reselect';

import {
  getSelectedDate,
  getAllPhotosAsArray,
  getAllTextsAsArray,
} from '@/reducers/selectors';
import { getSortedEvents } from './events';
import * as utils from '@/utils';

export const getSelectedMonthWithNeighbours = createSelector(
  [
    getSelectedDate,
  ],
  ({ month, year }) => {
    const {
      current: selectedMonth,
      next: nextMonth,
      previous: previousMonth,
    } = utils.getMonthTrio(month, year);

    return { selectedMonth, previousMonth, nextMonth };
  }
);

export const getCalendarDaysWithAssets = createSelector(
  [
    getAllPhotosAsArray,
    getAllTextsAsArray,
    getSelectedDate,
  ],
  (photos, texts, { month, year }) => {
    const numberOfDays = utils.getLastDayOfMonth(month, year);
    const daysArray = [];

    // eslint-disable-next-line no-plusplus
    for (let day = 1; day <= numberOfDays; day++) {
      const date = { year, month, day };
      const photo = photos.find(p => utils.matchesDate(p.date, date));

      daysArray.push({
        hasText: texts.some(t => utils.matchesDate(t.date, date)),
        date,
        thumbnailUrl: utils.getPhotoUrl(photo, 'small'),
      });
    }
    return daysArray;
  }
);

export const getCalendarOffset = createSelector(
  [
    getSelectedDate,
  ],
  ({ month, year }) => utils.getWeekOffset(month, year)
);

export const getSelectedCalendarEvents = createSelector(
  [
    getSelectedDate,
    getSortedEvents,
    getCalendarOffset,
  ],
  ({ month, year }, events, offset) => utils.getCalendarEvents(month, year, events, offset)
);

export const getCalendarEventsFromProps = createSelector(
  [
    (_, props) => props.date,
    getSortedEvents,
  ],
  ({ month, year }, events) => {
    const offset = utils.getWeekOffset(month, year);
    return utils.getCalendarEvents(month, year, events, offset);
  }
);

export const getCalendarEventsByDate = createSelector(
  [
    getSortedEvents,
  ],
  events => {
    const months = utils.getAllCalendarMonths();
    return months.reduce((acc, cur) => {
      const { month, year } = cur;
      const offset = utils.getWeekOffset(month, year);
      const mEvents = utils.getCalendarEvents(month, year, events, offset);

      acc[year] = acc[year] || {};
      acc[year][month] = mEvents;
      return acc;
    }, {});
  }
);
