import React, {
  memo,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import * as utils from '@/utils';

const OverviewDayGrid = ({ date }) => {
  const days = useMemo(() => utils.getCalendarDaysArray(date.month, date.year), [date.month, date.year]);
  const offset = useMemo(() => utils.getWeekOffset(date.month, date.year), [date.month, date.year]);

  return (
    <section className='ov-grid'>
      {days.map((d, i) => (
        <div
          key={`${d.date.day}-${d.date.month}-${d.date.year}`}
          className='ov-grid__day'
          style={{
            gridColumnStart: ((i + offset) % 7) + 1,
            gridRow: Math.floor((i + offset) / 7) + 1,
          }}
        />
      ))}
    </section>
  );
};

OverviewDayGrid.propTypes = {
  date: PropTypes.object.isRequired,
};

export default memo(OverviewDayGrid);
