import * as r from '@/constants/routes';

// order < 3 positions icon left, order > 3 positions icon right
export const CALENDAR_LINK = {
  type: r.INDEX,
  iconId: 'far fa-calendar-alt',
  order: 2,
};

export const INFO_LINK = {
  type: r.INFO,
  iconId: 'far fa-question-circle',
  order: 5,
};

export const MAP_LINK = {
  type: r.MAP,
  iconId: 'fas fa-globe-africa',
  order: 1,
};

export const MONTH_LINK = {
  type: r.MONTH,
  iconId: 'far fa-clock',
  order: 3,
};

export const LOGOUT_LINK = {
  type: r.LOGIN,
  iconId: 'fas fa-skull-crossbones',
  order: 6,
  payload: { logout: true },
};
