export const MAX_BOUNDS_HORIZONTAL = {
  WEST: -160,
  EAST: 200,
};

export const ZOOM_DELTA_INIT = 0.1;

export const mapOptions = {
  center: [30, 39],
  maxBounds: [[-90, MAX_BOUNDS_HORIZONTAL.WEST], [90, MAX_BOUNDS_HORIZONTAL.EAST]],
  maxBoundsViscosity: 1,
  maxZoom: 6,
  zoom: 2,
  zoomDelta: 0.8,
  zoomSnap: 0.1,
};

export const tileOptions = {
  attribution: '&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> | &copy; <a href="http://osm.org/copyright">OpenStreetMap</a>',
  url: 'https://api.mapbox.com/styles/v1/laalina/cjsw0scii02az1fpm7q1srwck/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibGFhbGluYSIsImEiOiJjanN3MDhpN24wOWVyM3lwamdvN3J2ZXYxIn0.OAuI509hE1P3pPZkXaafEw',
};
