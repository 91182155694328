import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as s from '@/selectors';

import CalendarDay from './CalendarDay';

const mapStateToProps = state => ({
  calendarDays: s.getCalendarDaysWithAssets(state),
  offset: s.getCalendarOffset(state),
  today: s.getToday(state),
});

const DayGrid = ({ calendarDays, offset, today }) => (
  <section className='calendar'>
    {calendarDays.map((day, i) => (
      <CalendarDay
        key={day.date.day}
        isToday={today.year === day.date.year
          && today.month === day.date.month
          && today.day === day.date.day}
        style={{ gridColumnStart: i === 0 ? offset + 1 : '' }}
        {...day}
      />
    ))}
  </section>
);

DayGrid.propTypes = {
  calendarDays: PropTypes.array.isRequired,
  offset: PropTypes.number.isRequired,
  today: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(DayGrid);
