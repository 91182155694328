import * as t from '@/actions/types';
import {
  PHOTO_ALBUMS,
  SYNC_PHOTOS,
} from '@/constants/routes';

const initialState = {};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  if (type === t.SEND_REQUEST && payload.url === PHOTO_ALBUMS) {
    return {
      ...state,
      ...payload.responseData,
    };
  }

  if (type === t.SEND_REQUEST && payload.dataType === SYNC_PHOTOS && payload.responseData.success) {
    const { albumId } = payload.requestData;

    return {
      ...state,
      [albumId]: {
        ...state[albumId],
        isSynced: true,
      },
    };
  }

  return state;
};

export const getPhotoAlbums = state => state.admin.photoAlbums;
export const getPhotoAlbumsAsArray = state => Object.values(state.admin.photoAlbums);
