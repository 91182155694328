import * as t from './types';

export const setSearchFilter = filter => ({
  type: t.SET_SEARCH_FILTER,
  payload: {
    filter,
  },
});

export const setLoading = (isLoading, loadingProps) => ({
  type: t.SET_LOADING,
  payload: {
    diff: isLoading ? 1 : -1,
    loadingProps,
  },
});

export const showAlert = (text, level) => ({
  type: t.ADD_ALERT,
  payload: {
    text,
    level,
  },
});

export const clearAlert = () => ({
  type: t.CLEAR_ALERT,
});

export const navigate = (navType, direction) => ({
  type: t.NAVIGATE,
  payload: {
    direction: direction === 'next' ? 1 : -1,
    navType,
  },
});
