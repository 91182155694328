import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '@/actions';
import * as s from '@/selectors';

import StatusSection from '@/components/admin/StatusSection';

const mapStateToProps = state => ({
  ...s.getAdminWsState(state),
});

const mapDispatchToProps = dispatch => ({
  onFetchList: () => dispatch(actions.fetchPhotoAlbums()),
  onSyncAlbum: albumId => dispatch(actions.syncPhotoAlbum(albumId)),
});

const Status = ({
  data,
  errors,
  success,
}) => (
  <div className='admin__row'>
    <StatusSection
      errors={errors.photos}
      info={`${data.photosSynced} / ${data.photosToSync} synced`}
      success={success.photos}
      title='Photos'
    />
    <StatusSection
      errors={errors.events}
      info={`${data.eventsSynced} / ${data.eventsToSync} synced`}
      success={success.events}
      title='Events'
    />
    <StatusSection
      errors={errors.countries}
      info={`${data.countriesSynced} / ${data.countriesToSync} synced`}
      success={success.countries}
      title='Countries'
    />
  </div>
);

Status.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  success: PropTypes.object,
};

Status.defaultProps = {
  data: {},
  errors: {},
  success: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Status);
