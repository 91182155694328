import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';

import * as actions from '@/actions';

const mapDispatchToProps = dispatch => ({
  onSelectDate: e => dispatch(actions.selectTextDate(new Date(e.target.value))),
});

const DatePicker = ({
  selectedDate,
  onSelectDate,
}) => (
  <input
    className='admin__select'
    required='required'
    type='date'
    value={moment().set({
      year: selectedDate.year,
      month: selectedDate.month,
      date: selectedDate.day,
    }).format('YYYY-MM-DD')}
    onChange={onSelectDate}
  />
);

DatePicker.propTypes = {
  selectedDate: PropTypes.object,
  onSelectDate: PropTypes.func,
};

DatePicker.defaultProps = {
  selectedDate: {},
  onSelectDate: () => null,
};

export default connect(null, mapDispatchToProps)(DatePicker);
