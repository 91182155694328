import { createSelector } from 'reselect';

import * as r from '@/constants/routes';

import { getSelectedDate } from '@/reducers/selectors';
import {
  getCommentsForSelectedView,
  getLocation,
  getEventForSelectedDay,
  getSelectedEvent,
} from '@/selectors';
import * as l from '@/constants/headerLinks';
import * as utils from '@/utils';

export const getHeaderProps = createSelector(
  [
    getLocation,
    getCommentsForSelectedView,
    getEventForSelectedDay,
    getSelectedDate,
    getSelectedEvent,
  ],
  ({ type, payload }, comments, dayEvent = {}, { year, month }, event) => {
    let title;
    let subtitle = '';
    const links = [];
    const monthLink = { ...l.MONTH_LINK, payload: { year, month } };

    switch (type) {
      case r.INDEX:
        title = 'Our Journey';
        links.push(
          l.INFO_LINK,
          l.MAP_LINK,
        );
        break;

      case r.MONTH:
        title = utils.getDisplayMonth(payload);
        links.push(
          l.INFO_LINK,
          l.MAP_LINK,
          l.CALENDAR_LINK,
        );
        break;

      case r.DAY:
      case r.DAY_PHOTO:
        title = utils.getDisplayDateWithWeekday(payload);
        subtitle = dayEvent.summary;
        links.push(
          monthLink,
          l.INFO_LINK,
          l.MAP_LINK,
          l.CALENDAR_LINK,
        );
        break;

      case r.EVENT:
      case r.EVENT_PHOTO:
        title = event.summary;
        subtitle = `${utils.getDisplayDate(event.start)} – ${utils.getDisplayDate(event.end)}`;
        links.push(
          { ...l.MONTH_LINK, payload: event.start },
          l.INFO_LINK,
          l.MAP_LINK,
          l.CALENDAR_LINK
        );
        break;

      case r.MAP:
        title = 'World Map';
        links.push(
          l.INFO_LINK,
          l.CALENDAR_LINK
        );
        break;

      case r.INFO:
        title = 'Welcome to our Blog!';
        links.push(
          monthLink,
          l.MAP_LINK,
          l.CALENDAR_LINK
        );
        break;

      case r.ADMIN:
        title = 'Admin';
        links.push(
          monthLink,
          l.INFO_LINK,
          l.LOGOUT_LINK,
          l.MAP_LINK,
          l.CALENDAR_LINK
        );
        break;

      default:
        title = '';
    }

    return {
      numberOfComments: comments.length,
      title,
      subtitle,
      links,
    };
  }
);
