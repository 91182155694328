import _merge from 'lodash.merge';
import * as t from '@/actions/types';

const initialState = {
  data: {
    pageNo: 0,
    countriesSynced: 0,
    countriesToSync: 0,
    eventsSynced: 0,
    eventsToSync: 0,
    photosSynced: 0,
    photosToSync: 0,
  },
  errors: {
    countries: [],
    events: [],
    photos: [],
  },
  progress: {
    countries: '',
    events: '',
    photos: '',
  },
  success: {
    countries: '',
    events: '',
    photos: '',
  },
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  if (type === t.RECEIVED_WS_TRANSMIT) {
    return _merge({}, state, payload);
  }

  if (type === t.CLEAR_WS_PROGRESS) {
    return {
      ...state,
      progress: initialState.progress,
    };
  }

  return state;
};

export const getAdminWsState = state => state.admin.websocket;
