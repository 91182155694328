import React from 'react';
import PropTypes from 'prop-types';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import * as utils from '@/utils';

// import 'quill-emoji';

class TextField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.text.content || '',
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text._id !== this.props.text._id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        value: this.props.text.content || '',
      });
    }
  }

  onChange(value) {
    this.props.onChangeText(value);
    this.setState({ value });
  }

  render() {
    const { text } = this.props;
    return (
      <div className='admin__row admin__row--wrapped'>
        {text.created && (
          <p>Latest version from {utils.getDisplayDateWithTime(text.created)}</p>
        )}

        <ReactQuill
          className='admin__text-field'
          modules={{
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'link', { header: 2 }],
                // ['emoji'],
              ],
              // handlers: { emoji() {} },
            },
            // 'emoji-toolbar': true,
          }}
          theme='snow'
          value={this.state.value}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

TextField.propTypes = {
  text: PropTypes.object,
  onChangeText: PropTypes.func,
};

TextField.defaultProps = {
  text: {},
  onChangeText: () => null,
};

export default TextField;
