import React from 'react';

import OverviewCalendar from '@/components/overview/OverviewCalendar';
import Header from '@/components/global/Header';

const Overview = () => [
  <Header key='header' />,
  <OverviewCalendar key='calendar' />,
];

export default Overview;
