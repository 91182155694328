import { combineReducers } from 'redux';

import { default as activeTab } from './activeTab';
import { default as countries } from './countries';
import { default as events } from './events';
import { default as photoAlbums } from './photoAlbums';
import { default as selectedDate } from './selectedDate';
import { default as selectedEvent } from './selectedEvent';
import { default as texts } from './texts';
import { default as websocket } from './websocket';

export default combineReducers({
  activeTab,
  countries,
  events,
  photoAlbums,
  selectedDate,
  selectedEvent,
  texts,
  websocket,
});

export * from './activeTab';
export * from './countries';
export * from './events';
export * from './photoAlbums';
export * from './selectedDate';
export * from './selectedEvent';
export * from './texts';
export * from './websocket';
