import React from 'react';
import PropTypes from 'prop-types';

const StatusSection = ({
  info,
  errors,
  success,
  title,
}) => (
  <div key='messages' className='admin__messages'>
    <h2>{title}</h2>
    <p>{info}</p>
    {success && <p>{success}</p>}
    {errors && errors.map(error => <p key={error}>{error}</p>)}
  </div>
);

StatusSection.propTypes = {
  errors: PropTypes.array,
  info: PropTypes.string,
  success: PropTypes.string,
  title: PropTypes.string,
};

StatusSection.defaultProps = {
  errors: [],
  info: '',
  success: '',
  title: '',
};

export default StatusSection;
