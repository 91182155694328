import { createSelector } from 'reselect';

import * as k from '@/constants/keywords';

import {
  getAdminTextsArray,
  getAdminWsState,
  getActiveTextTab,
  getSelectedTextDate,
  getSelectedTextEvent,
} from '@/reducers/selectors';

export const getFilteredAdminTexts = createSelector(
  [
    getAdminTextsArray,
    getActiveTextTab,
  ],
  (texts, type) => texts.filter(text => text.type === type)
);

export const getCurrentAdminText = createSelector(
  [
    getFilteredAdminTexts,
    getActiveTextTab,
    getSelectedTextDate,
    getSelectedTextEvent,
  ],
  (texts, type, date, eventId) => texts.find(text => {
    if (type === k.EVENT) {
      return eventId ? text.eventId === eventId : false;
    }
    return (
      text.date.year === date.year
      && text.date.month === date.month
      && text.date.day === date.day
    );
  })
);

export const getLoaderMessage = createSelector(
  [
    getAdminWsState,
  ],
  ({ data, progress }) => {
    let message = '';

    if (progress.photos) {
      message = `SYNCING PHOTOS
      ${progress.photos}
      Pages: ${data.pageNo}
      Photos: ${data.photosSynced} / ${data.photosToSync}`;

    } else if (progress.events) {
      message = `SYNCING EVENTS
      ${progress.events}
      ${data.eventsSynced} / ${data.eventsToSync}`;

    } else if (progress.countries) {
      message = `SYNCING COUNTRIES
      ${progress.countries}
      ${data.countriesSynced} / ${data.countriesToSync}`;
    }

    return message;
  }
);
