import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '@/actions';
import * as selectors from '@/selectors';

const mapStateToProps = state => ({
  isSynced: selectors.isEventsSynced(state),
  syncedEvents: selectors.getSyncedEvents(state),
});

const mapDispatchToProps = dispatch => ({
  onSync: () => {
    dispatch(actions.clearWsProgress());
    dispatch(actions.syncEvents());
  },
});

const Events = ({
  isSynced,
  syncedEvents,
  onSync,
}) => [
  <button
    key='sync-button'
    className='button'
    disabled={isSynced}
    type='button'
    onClick={onSync}
  >
    Sync Events
  </button>,
  isSynced && <p key='success'>{syncedEvents} synced.</p>,
];

Events.propTypes = {
  isSynced: PropTypes.bool,
  syncedEvents: PropTypes.number,
  onSync: PropTypes.func,
};

Events.defaultProps = {
  isSynced: false,
  syncedEvents: null,
  onSync: () => null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
