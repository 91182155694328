import * as t from '@/actions/types';
import * as c from '@/constants/dataTypes';

const initialState = {};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  if (type === t.DATA_FETCHED) {

    if (payload.dataType === c.EVENTS) {
      return {
        ...state,
        events: true,
      };

    } else if (payload.dataType === c.COUNTRIES) {
      return {
        ...state,
        countries: true,
      };

    } else if (payload.query && payload.query.month != null && payload.query.year != null) {
      const { dataType } = payload;
      const { month, year } = payload.query;
      const yearState = state[year] || {};
      const monthState = yearState[month] || {};

      return {
        ...state,
        [year]: {
          ...yearState,
          [month]: {
            ...monthState,
            [dataType]: true,
          },
        },
      };

    } else if (payload.eventId) {
      const { dataType, eventId } = payload;
      const eventState = state[eventId];

      return {
        ...state,
        [eventId]: {
          ...eventState,
          [dataType]: true,
        },
      };
    }
  }

  return state;
};

export const getCacheInfo = state => state.data.cacheInfo;
