import * as actions from '@/actions';
import * as t from '@/actions/types';

export default store => next => action => {
  const { type, payload } = action;

  if (type === t.SEND_REQUEST) {
    const loadingProps = { ...payload.requestData };
    store.dispatch(actions.setLoading(true, loadingProps));

    payload.finallyFns = payload.finallyFns || [];
    payload.finallyFns.push(
      dispatch => dispatch(actions.setLoading(false, loadingProps)),
    );
  }

  next(action);
};
