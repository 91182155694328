import { combineReducers } from 'redux';

import { default as appToken } from './appToken';
import { default as googleToken } from './googleToken';
import { default as selectedDate } from './selectedDate';
import { default as today } from './today';

export default combineReducers({
  appToken,
  googleToken,
  selectedDate,
  today,
});

export * from './appToken';
export * from './googleToken';
export * from './selectedDate';
export * from './today';
