import * as t from './types';

export const loginUser = (token, forward = true) => ({
  type: t.LOGIN_USER,
  payload: {
    token,
    forward,
  },
});

export const logoutUser = () => ({
  type: t.LOGOUT_USER,
});

export const initializeApp = () => ({
  type: t.INITIALIZE_APP,
});

export const setGoogleToken = token => ({
  type: t.SET_GOOGLE_TOKEN,
  payload: { token },
});

export const connectToWebsocket = () => ({
  type: t.CONNECT_WEBSOCKET,
});

export const disconnectWebsocket = () => ({
  type: t.DISCONNECT_WEBSOCKET,
});

export const receivedWsTransmit = transmit => ({
  type: t.RECEIVED_WS_TRANSMIT,
  payload: transmit,
});

export const clearWsProgress = () => ({
  type: t.CLEAR_WS_PROGRESS,
});
