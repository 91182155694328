import { NOT_FOUND } from 'redux-first-router';

import * as actions from '@/actions';
import * as r from '@/constants/routes';
import * as utils from '@/utils';

import Admin from '@/components/routes/Admin';
import Day from '@/components/routes/Day';
import Event from '@/components/routes/Event';
import Info from '@/components/routes/Info';
import Login from '@/components/routes/Login';
import Map from '@/components/routes/Map';
import Month from '@/components/routes/Month';
import Overview from '@/components/routes/Overview';

export default {
  [r.INDEX]: {
    path: '/',
    component: Overview,
    displayTitle: 'Our Journey',
    thunk: utils.fetchOverviewData,
  },
  [r.ADMIN]: {
    path: '/admin',
    component: Admin,
    thunk: utils.authorizeAdmin,
  },
  [r.DAY]: {
    path: '/date/:year/:month/:day',
    coerceNumbers: true,
    component: Day,
    displayTitle: 'Day',
    toPath: utils.monthToString,
    fromPath: utils.dateToNumber,
    thunk: utils.fetchMonthlyData,
  },
  [r.DAY_PHOTO]: {
    path: '/date/:year/:month/:day/photo/:photoId',
    coerceNumbers: true,
    component: Day,
    displayTitle: 'Photo',
    toPath: utils.monthToString,
    fromPath: utils.dateToNumber,
    thunk: utils.fetchMonthlyData,
  },
  [r.EVENT]: {
    path: '/event/:eventId',
    component: Event,
    displayTitle: 'Event',
    thunk: utils.fetchEventData,
  },
  [r.EVENT_PHOTO]: {
    path: '/event/:eventId/photo/:photoId',
    component: Event,
    displayTitle: 'Photo',
    thunk: utils.fetchEventData,
  },
  [r.INFO]: {
    path: '/info',
    component: Info,
  },
  [r.LOGIN]: {
    path: '/login',
    component: Login,
    thunk: utils.autoForwardAdmin,
  },
  [r.MAP]: {
    path: '/map',
    component: Map,
    thunk: utils.fetchMapData,
  },
  [r.MONTH]: {
    path: '/date/:year/:month',
    coerceNumbers: true,
    component: Month,
    displayTitle: 'Month',
    toPath: utils.monthToString,
    fromPath: utils.dateToNumber,
    thunk: utils.fetchMonthlyData,
  },
  [NOT_FOUND]: {
    thunk(dispatch) {
      dispatch(actions.redirectIndex());
    },
  },
};

export const options = {
  onBeforeChange(dispatch, _, { action }) {
    const { type, payload } = action;

    if (type === r.LOGIN && payload.logout) {
      dispatch(actions.logoutUser());
    }
  },
};
