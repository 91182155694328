export const MESSAGES = {
  ALREADY_SUBSCRIBED: 'It seems that you already subscribed to our newsletter earlier, so we will keep you on that list.',
  SUBSCRIBED_SUCCESS: 'You are now subscribed to our Newsletter. If it does not arrive soon, check your spam folder.',
};

export const VALIDATION = {
  EMAIL: 'Please enter your email address. We promise we will never share it.',
  NAME: 'Please enter your name.',
  MESSAGE: 'What was that? Looks like you forgot to enter your message!',
  PHOTO_ID: 'Please enter a photo id.',
};

export const ERRORS = {
  CREDENTIALS: 'Your login info is incorrect.',
  COULD_NOT_SUBSCRIBE: 'Sorry, we could not subscribe you. Please try again!',
  COULD_NOT_SAVE_COMMENT: 'Sorry, we could not save your comment. Please try again!',
  GENERIC: 'Something went wrong!',
};

export const ALERT_LEVEL = {
  ERROR: 'error',
  INFO: 'info',
  VALIDATION: 'validation',
};
