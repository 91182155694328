import { createSelector } from 'reselect';

import * as r from '@/constants/routes';

import {
  getCurrentRouteType,
  getCurrentRouteProps,
} from '@/selectors/location';
import { getSelectedMonthWithNeighbours } from '@/selectors/calendar';
import { getSelectedDayWithNeighbours } from '@/selectors/day';
import { getSelectedPhotoWithNeighbours } from '@/selectors/photos';

export const getNavProps = createSelector(
  [
    getCurrentRouteType,
    getCurrentRouteProps,
    getSelectedMonthWithNeighbours,
    getSelectedDayWithNeighbours,
    getSelectedPhotoWithNeighbours,
  ],
  (
    type,
    props,
    { previousMonth, nextMonth },
    { previousDay, nextDay },
    { previousPhoto, nextPhoto }
  ) => {
    const currentType = type === r.INDEX ? r.MONTH : type;
    let neighbours = {};

    switch (currentType) {
      case r.MONTH:
        neighbours = {
          previous: previousMonth,
          next: nextMonth,
        };
        break;
      case r.DAY:
        neighbours = {
          previous: previousDay,
          next: nextDay,
        };
        break;
      case r.DAY_PHOTO:
      case r.EVENT_PHOTO:
        neighbours = {
          previous: previousPhoto,
          next: nextPhoto,
        };
        break;
      default:
        break;
    }

    return {
      displayTitle: props.displayTitle,
      type: type === r.INDEX ? r.MONTH : type,
      ...neighbours,
    };
  }
);
