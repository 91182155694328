import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Link from 'redux-first-router-link';

import * as selectors from '@/selectors';
import * as utils from '@/utils';

const mapStateToProps = (state, { photo }) => {
  const route = selectors.getLocation(state);

  return {
    linkTo: {
      type: `${route.type.replace('_PHOTO', '')}_PHOTO`,
      payload: { ...route.payload, photoId: photo._id },
    },
  };
};

const PhotoGridItem = ({ linkTo, photo }) => {
  const photoUrl = utils.getPhotoUrl(photo, 'medium');
  const isPortrait = photo.ratio < 1;
  const isPanorama = photo.ratio > 2;
  const columnSpan = isPanorama ? 'span 2' : 'auto';

  let rowSpan = isPortrait ? 3 : 2;
  if (photo.description) {
    rowSpan += 1;
  }

  return (
    <Link
      className='photo-grid__photo'
      style={{
        backgroundImage: `url(${photoUrl})`,
        gridRowEnd: `span ${rowSpan}`,
        gridColumnEnd: columnSpan,
      }}
      to={linkTo}
    >
      {photo.resourceType === 'video' && <span className='photo-grid__play-icon fas fa-play-circle' />}
      {photo.description && <div className='photo-grid__description'>{photo.description}</div>}
    </Link>
  );
};

PhotoGridItem.propTypes = {
  linkTo: PropTypes.object,
  photo: PropTypes.object.isRequired,
};

PhotoGridItem.defaultProps = {
  linkTo: {},
};

export default connect(mapStateToProps)(PhotoGridItem);
