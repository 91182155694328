import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as s from '@/selectors';

import Comment from './Comment';
import CommentForm from './CommentForm';

const mapStateToProps = state => ({
  comments: s.getCommentsForSelectedView(state),
});

const Comments = ({ comments }) => (
  <section className='comments' id='comments'>
    <div className='comments__header'>{comments.length} comments</div>
    {comments.map(comment => <Comment key={comment._id} {...comment} />)}
    <CommentForm />
  </section>
);

Comments.propTypes = {
  comments: PropTypes.array,
};

Comments.defaultProps = {
  comments: [],
};

export default connect(mapStateToProps)(Comments);
