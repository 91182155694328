import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as s from '@/selectors';

import NavButton from './NavButton';

const mapStateToProps = state => ({
  navProps: s.getNavProps(state),
});

const Navigation = ({ navProps }) => [
  navProps.previous && (
    <NavButton
      key='back-button'
      buttonText={`Previous\n${navProps.displayTitle}`}
      classNames='nav-button nav-button--back'
      direction='back'
      iconId='fas fa-angle-left'
      payload={navProps.previous}
      type={navProps.type}
    />
  ),
  navProps.next && (
    <NavButton
      key='next-button'
      buttonText={`Next\n${navProps.displayTitle}`}
      classNames='nav-button nav-button--next'
      direction='next'
      iconId='fas fa-angle-right'
      payload={navProps.next}
      type={navProps.type}
    />
  ),
];

Navigation.propTypes = {
  navProps: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Navigation);
