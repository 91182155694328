import * as t from '@/actions/types';
import { SYNC_EVENTS } from '@/constants/routes';

const initialState = {
  isSynced: false,
  syncedEvents: null,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  if (type === t.SEND_REQUEST && payload.dataType === SYNC_EVENTS && payload.responseData.success) {
    return {
      isSynced: true,
      syncedEvents: payload.responseData.syncedEvents,
    };
  }

  return state;
};

export const getSyncedEvents = state => state.admin.events.syncedEvents;
export const isEventsSynced = state => state.admin.events.isSynced;
