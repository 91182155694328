import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '@/actions';
import * as s from '@/selectors';
import * as c from '@/constants/formNames';

const mapStateToProps = state => ({
  inputValues: s.getFormInput(state, c.NEWSLETTER),
});

const mapDispatchToProps = dispatch => ({
  onChangeValue: (key, value) => dispatch(actions.onChangeFormValue(c.NEWSLETTER, key, value)),
  onSubscribe: () => dispatch(actions.subscribeToNewsletter()),
});

const NewsletterForm = ({
  inputValues,
  onChangeValue,
  onSubscribe,
}) => (
  <form className='info__form'>
    <label htmlFor={c.NAME}>Name:</label>
    <input
      className='input'
      id={c.NAME}
      placeholder='Your Name'
      type='text'
      value={inputValues.name}
      onChange={e => onChangeValue(c.NAME, e.target.value)}
    />
    <label htmlFor={c.EMAIL}>Email:</label>
    <input
      className='input'
      id={c.EMAIL}
      placeholder='your@email.com'
      type='email'
      value={inputValues.email}
      onChange={e => onChangeValue(c.EMAIL, e.target.value)}
    />
    <button
      className='button button--dark'
      type='button'
      onClick={onSubscribe}
    >
      Subscribe to our Newsletter
    </button>
  </form>
);

NewsletterForm.propTypes = {
  inputValues: PropTypes.object,
  onChangeValue: PropTypes.func.isRequired,
  onSubscribe: PropTypes.func.isRequired,
};

NewsletterForm.defaultProps = {
  inputValues: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterForm);
