import * as c from '@/constants/dataTypes';
import * as t from '@/actions/types';

const initialState = {};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  if (type === t.DATA_FETCHED && payload.dataType === c.EVENTS) {
    return {
      ...state,
      ...payload.responseData,
    };
  }

  return state;
};

export const getAllEvents = state => state.data.events;
export const getAllEventsAsArray = state => Object.values(state.data.events);
