import '@babel/polyfill';

import './debug';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import * as serviceWorker from './serviceWorker';
import store from './store';

import * as actions from '@/actions';
import App from './components/App';

if (!window.location.origin) {
  const port = window.location.port ? `:${window.location.port}` : '';
  window.location.origin = `${window.location.protocol}//${window.location.hostname}${port}`;
}

store.dispatch(actions.initializeApp());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector('#app')
);

serviceWorker.unregister();
