import React from 'react';
import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';

import * as routes from '@/constants/routes';

const PopupSection = ({ type, events, title }) => (
  events.length > 0 && [
    <h3 key='popup-title' className={`popup-title popup-title--${type}`}>{title}</h3>,
    <ul key='popup-list' className={`popup-list popup-list--${type}`}>
      {events.map(event => (
        <li key={event._id}>
          <Link
            to={{ type: routes.EVENT, payload: { eventId: event._id } }}
          >
            {event.title}
          </Link>
        </li>
      ))}
    </ul>,
  ]
);

PopupSection.propTypes = {
  events: PropTypes.array,
  title: PropTypes.string,
  type: PropTypes.string,
};

PopupSection.defaultProps = {
  events: [],
  title: "We've been here:",
  type: 'past',
};

export default PopupSection;
