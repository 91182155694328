import React from 'react';
import PropTypes from 'prop-types';

import * as k from '@/constants/keywords';

const TextTab = ({
  activeTab,
  title,
  type,
  onChange,
}) => (
  <div className='tab-bar__item'>
    <input
      checked={activeTab === type}
      className='tab-bar__input'
      id={type}
      name='active-tab'
      type='radio'
      value={type}
      onChange={() => onChange(type)}
    />
    <label className='tab-bar__label' htmlFor={type}>
      {title}
    </label>
  </div>
);

TextTab.propTypes = {
  activeTab: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

TextTab.defaultProps = {
  activeTab: k.DATE,
  title: '',
  onChange: () => null,
};

export default TextTab;
