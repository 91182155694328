import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '@/actions';
import * as k from '@/constants/keywords';
import * as selectors from '@/selectors';

import TextTab from './TextTab';

const mapStateToProps = state => ({
  activeTab: selectors.getActiveTextTab(state),
});

const mapDispatchToProps = dispatch => ({
  onChangeTab: type => dispatch(actions.changeAdminTextTab(type)),
});

const TextTabBar = ({
  activeTab,
  onChangeTab,
}) => (
  <div className='admin__row tab-bar'>
    <TextTab
      activeTab={activeTab}
      title='Date'
      type={k.DATE}
      onChange={onChangeTab}
    />
    <TextTab
      activeTab={activeTab}
      title='Event'
      type={k.EVENT}
      onChange={onChangeTab}
    />
    <TextTab
      activeTab={activeTab}
      title='Newsletter'
      type={k.NEWSLETTER}
      onChange={onChangeTab}
    />
  </div>
);

TextTabBar.propTypes = {
  activeTab: PropTypes.string,
  onChangeTab: PropTypes.func,
};

TextTabBar.defaultProps = {
  activeTab: k.DATE,
  onChangeTab: () => null,
};

export default connect(mapStateToProps, mapDispatchToProps)(TextTabBar);
