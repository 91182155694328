import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { EVENT_PHOTO } from '@/constants/routes';
import * as s from '@/selectors';

import Header from '@/components/global/Header';
import Navigation from '@/components/global/Navigation';
import PhotoGrid from '@/components/global/PhotoGrid';
import Slideshow from '@/components/global/Slideshow';

const mapStateToProps = state => ({
  isPhotoRoute: s.getCurrentRouteType(state) === EVENT_PHOTO,
  photos: s.getPhotosForSelectedEvent(state),
  text: s.getTextForSelectedEvent(state),
});

const Event = ({ isPhotoRoute, photos, text }) => [
  <Header key='header' />,
  <PhotoGrid key='photo-grid' isScrollable={!isPhotoRoute} photos={photos} text={text} />,
  isPhotoRoute && <Navigation key='navigation' />,
  isPhotoRoute && <Slideshow key='slideshow' />,
];

Event.propTypes = {
  isPhotoRoute: PropTypes.bool,
  photos: PropTypes.array,
  text: PropTypes.object,
};

Event.defaultProps = {
  isPhotoRoute: false,
  photos: [],
  text: null,
};

export default connect(mapStateToProps)(Event);
