import { createSelector } from 'reselect';

import {
  getAllEvents,
  getAllPhotos,
} from '@/reducers/selectors';
import * as r from '@/constants/routes';

export const getLocation = state => state.location;
export const getCurrentRouteType = state => state.location.type;
export const getCurrentRoutePayload = state => state.location.payload;

export const getCurrentRouteProps = createSelector(
  [
    getLocation,
    getCurrentRouteType,
  ],
  (location, route) => location.routesMap[route]
);

export const getSelectedEvent = createSelector(
  [
    getCurrentRoutePayload,
    getAllEvents,
  ],
  ({ eventId }, events) => events[eventId] || { start: {}, end: {} }
);

export const getSelectedPhoto = createSelector(
  [
    getCurrentRoutePayload,
    getAllPhotos,
  ],
  ({ photoId }, photos) => photos[photoId] || {}
);

export const getSlideshowBacklink = createSelector(
  [
    getCurrentRouteType,
    getCurrentRoutePayload,
  ],
  (currentType, currentPayload) => {
    const type = currentType === r.DAY_PHOTO ? r.DAY : r.EVENT;
    const payload = { ...currentPayload };
    delete payload.photoId;

    return { type, payload };
  }
);
