export const calculateMaxColumns = () => {
  const screenWidth = document.body.offsetWidth;
  let max;
  let min = 2;

  if (screenWidth < 636) {
    min = 1;
    max = 1;
  } else if (screenWidth < 958) {
    max = 2;
  } else if (screenWidth < 1280) {
    max = 3;
  } else {
    max = 4;
  }

  return { min, max };
};

export const contentFitsContainer = container => {
  const availableContentHeight = container.offsetHeight;
  const neededContainerHeight = container.scrollHeight;

  return neededContainerHeight <= availableContentHeight;
};

export const getIncreasedContainerSpan = ({ rowSpan, columnSpan }, maxColumns) => {
  if (rowSpan > 6 && columnSpan < maxColumns) {
    return {
      rowSpan: 2,
      columnSpan: columnSpan + 1,
    };
  }
  return {
    rowSpan: rowSpan + 1,
    columnSpan,
  };
};
