import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as s from '@/selectors';

import CalendarEvent from './CalendarEvent';

const mapStateToProps = state => ({
  calendarEvents: s.getSelectedCalendarEvents(state),
});

class EventGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focussedEvent: null,
    };

    this.onFocusEvent = this.onFocusEvent.bind(this);
  }

  onFocusEvent(eventId) {
    this.setState({
      focussedEvent: eventId,
    });
  }

  render() {
    return (
      <section className='calendar calendar--events'>
        {this.props.calendarEvents.map((event, i) => (
          <CalendarEvent
            // eslint-disable-next-line react/no-array-index-key
            key={`${event._id}-${i}`}
            focussed={this.state.focussedEvent === event._id}
            style={{
              gridColumn: i === 0
                ? `${event.startDay + 1} / span ${event.length}`
                : `auto / span ${event.length}`,
            }}
            onFocusEvent={this.onFocusEvent}
            {...event}
          />
        ))}
      </section>
    );
  }
}

EventGrid.propTypes = {
  calendarEvents: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(EventGrid);
