import * as t from '@/actions/types';

const initialState = {};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  if (type === t.SELECT_TEXT_DATE) {
    return payload.date;
  }

  return state;
};

export const getSelectedTextDate = state => state.admin.selectedDate;
