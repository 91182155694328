import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as r from '@/constants/routes';
import * as s from '@/selectors';

import Alert from './global/Alert';
import Loader from './global/Loader';

import '@/styles/index.scss';

const mapStateToProps = state => {
  const route = state.location.type;
  const RouteComponent = state.location.routesMap[route].component;
  return {
    hasAlert: s.hasAlert(state),
    route: state.location,
    RouteComponent,
    isLoading: s.displayLoadingState(state),
  };
};

class App extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.route.pathname !== this.props.route.pathname) {

      const protectedRoutes = [r.DAY_PHOTO, r.EVENT_PHOTO];
      const wasProtectedRoute = protectedRoutes.includes(prevProps.route.type);
      const isProtectedRoute = protectedRoutes.includes(this.props.route.type);
      if (!wasProtectedRoute && !isProtectedRoute) {
        const mainElement = document.querySelector('#main');
        if (mainElement) {
          mainElement.scrollTop = 0;
        }
      }
    }
  }

  render() {
    const {
      hasAlert,
      isLoading,
      RouteComponent,
    } = this.props;

    return [
      isLoading && <Loader key='loader' />,
      hasAlert && <Alert key='alert' />,
      <RouteComponent key='route-comp' />,
    ];
  }
}

App.propTypes = {
  hasAlert: PropTypes.bool,
  isLoading: PropTypes.bool,
  route: PropTypes.object,
  RouteComponent: PropTypes.func,
};

App.defaultProps = {
  hasAlert: false,
  isLoading: false,
  route: {},
  RouteComponent: null,
};

export default connect(mapStateToProps)(App);
