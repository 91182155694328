import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '@/actions';
import * as selectors from '@/selectors';

const mapStateToProps = state => ({
  albums: selectors.getPhotoAlbumsAsArray(state),
});

const mapDispatchToProps = dispatch => ({
  onFetchList: () => dispatch(actions.fetchPhotoAlbums()),
  onSyncAlbum: albumId => {
    dispatch(actions.clearWsProgress());
    dispatch(actions.syncPhotoAlbum(albumId));
  },
});

class Photos extends React.Component {
  constructor(props) {
    super(props);

    this.albumSelector = React.createRef();
    this.onSyncAlbum = this.onSyncAlbum.bind(this);
  }

  onSyncAlbum() {
    const albumSelector = this.albumSelector.current;
    this.props.onSyncAlbum(albumSelector.value);
  }

  render() {
    const {
      albums,
      onFetchList,
    } = this.props;
    return (
      <div className='admin__row'>
        <button
          key='list-button'
          className='button'
          type='button'
          onClick={onFetchList}
        >
          Fetch Album List
        </button>
        <select
          key='album-select'
          ref={this.albumSelector}
          className='admin__select'
        >
          {albums.map(album => (
            <option
              key={album.id}
              disabled={album.isSynced}
              value={album.id}
            >
              {album.title} ({album.mediaItemsCount} Photos){album.isSynced && ' [SYNCED]'}
            </option>
          ))}
        </select>
        <button
          key='sync-button'
          className='button'
          type='button'
          onClick={this.onSyncAlbum}
        >
          Sync Album
        </button>
      </div>
    );
  }
}

Photos.propTypes = {
  albums: PropTypes.array,
  onFetchList: PropTypes.func,
  onSyncAlbum: PropTypes.func,
};

Photos.defaultProps = {
  albums: [],
  onFetchList: () => null,
  onSyncAlbum: () => null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Photos);
