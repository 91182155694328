import * as actions from '@/actions';
import * as t from '@/actions/types';

const API_ADDRESS = process.env.REACT_APP_API_URL || window.location.origin;
const API_URL = `${API_ADDRESS}/api/v2`;
const WS_URL = API_URL.replace('http', 'ws');

let ws;

export default ({ dispatch }) => next => async action => {
  const { type } = action;
  if (type === t.CONNECT_WEBSOCKET && !ws) {
    ws = new window.WebSocket(WS_URL);

    ws.onmessage = msgStr => {
      const transmit = JSON.parse(msgStr.data);
      dispatch(actions.receivedWsTransmit(transmit));
    };
  }

  if (type === t.DISCONNECT_WEBSOCKET && ws) {
    ws.close();
  }

  next(action);
};
