import { createSelector } from 'reselect';

import {
  getAllEventsAsArray,
  getSelectedDate,
} from '@/selectors';
import * as utils from '@/utils';

export const getSortedEvents = createSelector(
  [
    getAllEventsAsArray,
  ],
  events => events.sort((ev1, ev2) => {
    const startDate1 = new Date(ev1.start.year, ev1.start.month, ev1.start.day);
    const startDate2 = new Date(ev2.start.year, ev2.start.month, ev2.start.day);
    return startDate1 > startDate2 ? 1 : -1;
  })
);

export const getCurrentEvent = createSelector(
  [
    getAllEventsAsArray,
  ],
  events => events.find(e => utils.locateInTime(e.start, e.end) === 'current')
);

export const getEventForSelectedDay = createSelector(
  [
    getSortedEvents,
    getSelectedDate,
  ],
  (events, selectedDate) => events.find(e => utils.locateInTime(e.start, e.end, selectedDate) === 'current')
);
