import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '@/actions';

const mapDispatchToProps = dispatch => ({
  onSubmit: (user, password) => dispatch(actions.submitLogin(user, password)),
});

class Admin extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      user: '',
      password: '',
    };

    this.onChangeUser = this.onChangeUser.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChangeUser(e) {
    this.setState({ user: e.target.value });
  }

  onChangePassword(e) {
    this.setState({ password: e.target.value });
  }

  onSubmit(e) {
    this.props.onSubmit(this.state.user, this.state.password);
    e.preventDefault();
  }

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <label htmlFor='user'>User:</label>
        <input
          id='user'
          type='text'
          onChange={this.onChangeUser}
        />
        <label htmlFor='password'>Password:</label>
        <input
          id='password'
          type='password'
          onChange={this.onChangePassword}
        />
        <button className='button' type='submit'>
          Log In
        </button>
      </form>
    );
  }
}

Admin.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(Admin);
