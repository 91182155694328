import _get from 'lodash.get';
import * as t from '@/actions/types';
import * as s from '@/selectors';
import * as c from '@/constants/dataTypes';

const validDataTypes = [
  c.COMMENTS,
  c.COUNTRIES,
  c.EVENTS,
  c.PHOTOS,
  c.TEXTS,
];

export default ({ getState }) => next => action => {
  const { type, payload = {} } = action;
  const { method = 'get' } = payload;

  if (type === t.SEND_REQUEST && method === 'get' && validDataTypes.includes(payload.dataType)) {
    const { requestData = {}, dataType } = payload;
    const cacheInfo = s.getCacheInfo(getState());

    if (dataType === c.EVENTS) {
      if (cacheInfo.events) {
        return;
      }
    } else if (dataType === c.COUNTRIES) {
      if (cacheInfo.countries) {
        return;
      }
    } else if (requestData.eventId) {
      if (_get(cacheInfo, `${requestData.eventId}.${dataType}`)) {
        return;
      }
    } else if (_get(cacheInfo, `${requestData.year}.${requestData.month}.${dataType}`)) {
      return;
    }
  }

  next(action);
};
