import * as t from '@/actions/types';
import * as k from '@/constants/keywords';

const initialState = k.DATE;

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  if (type === t.CHANGE_ADMIN_TEXT_TAB) {
    return payload.textType;
  }

  return state;
};

export const getActiveTextTab = state => state.admin.activeTab;
