import * as t from '@/actions/types';

const initialState = {
  date: {},
  event: {},
  generic: 0,
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  if (type === t.SET_LOADING) {
    const { month, year, eventId } = payload.loadingProps;
    if (month != null && year != null) {
      const dateYearState = state.date[year] || {};
      const dateMonthState = dateYearState[month] || 0;

      return {
        ...state,
        date: {
          ...state.date,
          [year]: {
            ...dateYearState,
            [month]: dateMonthState + payload.diff,
          },
        },
      };
    } else if (eventId != null) {
      return {
        ...state,
        event: {
          ...state.event,
          [eventId]: (state.event[eventId] || 0) + payload.diff,
        },
      };
    }

    return {
      ...state,
      generic: state.generic + payload.diff,
    };
  }

  return state;
};

export const getLoadingState = state => state.ui.loading;
