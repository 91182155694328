import { createSelector } from 'reselect';

import { getAllCommentsAsArray } from '@/reducers/selectors';
import {
  getCurrentRouteType,
  getCurrentRoutePayload,
} from './location';
import * as r from '@/constants/routes';
import * as utils from '@/utils';

export const getSortedComments = createSelector(
  [
    getAllCommentsAsArray,
  ],
  comments => comments.sort((a, b) => a.created - b.created)
);

export const getCommentsForSelectedView = createSelector(
  [
    getSortedComments,
    getCurrentRouteType,
    getCurrentRoutePayload,
  ],
  (comments, type, payload) => {
    if (type === r.DAY) {
      return comments.filter(c => utils.matchesDate(c.date, payload));
    }

    if (type === r.EVENT) {
      return comments.filter(c => c.eventId === payload.eventId);
    }

    return [];
  }
);
