import * as t from './types';

export const onChangeFormValue = (form, key, value) => ({
  type: t.CHANGE_FORM_VALUE,
  payload: {
    form,
    key,
    value,
  },
});

export const clearForm = form => ({
  type: t.CLEAR_FORM,
  payload: { form },
});
