/* eslint-disable quotes */

export default [
  `We're glad you made it here! You know, it's kind of hard keeping in touch with you – whether you're back home or we met somewhere on the road. We have so many things going on: In each new apartment we need to make ourselves at home and get used to the new bed, the new kitchen, the new neighbours; each new coworking space means adapting our routine to new travel times and opening hours; each new city means exploring new streets, figuring out anew how to get around, finding restaurants, barber shops and doctors; each new country means learning new customs, sometimes a new language, trying new food and meeting new people. Then there's work. Side projects. Making plans for the next few months. Time spent on planes, buses, tuktuks, and waiting in line. And sometimes we just need a "day off" from it all.`,

  `Don't get us wrong: this is great, no, it's amazing – and we wouldn't have it any other way! It just means we have little space for anything else. But it doesn't mean we're not thinking about you. Or that we don't miss you. We do!`,

  `On this page we can at least let you know how we're doing on our travelling-the-world-remote-working-adventure. And we can make plans to meet! When you skip forward in our calendar you can see where we plan to be at what time. If you're headed the same way: please let us know! And if you're planning a vacation and haven't decided where to go yet – maybe let our plans inspire you and meet us somewhere?`,

  `Otherwise we'll see you back home. When we have one again. You'll always be welcome.`,

  `With love from all over the world,\nAlex & Alina`,
];
