import { redirect } from 'redux-first-router';

import * as r from '@/constants/routes';

export const redirectIndex = () => redirect({
  type: r.INDEX,
});

export const redirectLogin = () => redirect({
  type: r.LOGIN,
});

export const redirectAdmin = () => redirect({
  type: r.ADMIN,
});
