import * as r from '@/constants/routes';

const today = new Date();

const initialState = {
  year: today.getUTCFullYear(),
  month: today.getUTCMonth(),
  day: today.getUTCDate(),
};

export default (state = initialState, action = {}) => {
  const { type, payload } = action;

  if (type === r.MONTH || type === r.DAY) {
    return payload;

  } else if (type === r.DAY_PHOTO) {
    return {
      year: payload.year,
      month: payload.month,
      day: payload.day,
    };

  } else if (type === r.INDEX) {
    return initialState;
  }

  return state;
};

export const getSelectedDate = state => state.session.selectedDate;
