import React from 'react';
import PropTypes from 'prop-types';
import Link from 'redux-first-router-link';

import { DAY } from '@/constants/routes';

const CalendarDay = ({
  date,
  hasText,
  isToday,
  thumbnailUrl,
  style,
}) => (
  <div className={`calendar__day${isToday ? ' calendar__day--today' : ''}`} style={style}>
    {thumbnailUrl && (
      <Link
        className='calendar__thumbnail'
        style={{ backgroundImage: `url(${thumbnailUrl})` }}
        to={{ type: DAY, payload: date }}
      />
    )}
    {thumbnailUrl && hasText && <span className='calendar__icon fas fa-align-left' />}
    <p className='calendar__number'>{date.day}</p>
  </div>
);

CalendarDay.propTypes = {
  date: PropTypes.object.isRequired,
  hasText: PropTypes.bool,
  isToday: PropTypes.bool,
  style: PropTypes.object,
  thumbnailUrl: PropTypes.string,
};

CalendarDay.defaultProps = {
  hasText: false,
  isToday: false,
  style: {},
  thumbnailUrl: '',
};

export default CalendarDay;
