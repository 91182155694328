import React from 'react';
import PropTypes from 'prop-types';

const AdminSection = ({ children, title }) => (
  <fieldset className='admin__section'>
    <legend>{title}</legend>
    {children}
  </fieldset>
);

AdminSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired,
};

export default AdminSection;
