import ajax from '@/adapters/request';
import * as t from '@/actions/types';

import * as selectors from '@/selectors';

const API_ADDRESS = process.env.REACT_APP_API_URL || window.location.origin;
const API_URL = `${API_ADDRESS}/api/v2`;

export default ({ dispatch, getState }) => next => async action => {
  const { type, payload } = action;

  if (type === t.SEND_REQUEST) {
    const {
      isInternal = true,
      isGoogle = false,
      dataType = '',
      method = 'get',
      requestData = {},
      successFns = [],
      errorFns = [],
      finallyFns = [],
      url = '',
      useToken = false,
    } = payload;

    const requestUrl = `${isInternal ? API_URL : url}/${dataType}`;
    const sessionToken = selectors.getSessionToken(getState());
    const headers = {};

    if (isGoogle) {
      headers.Authorization = `Bearer ${selectors.getGoogleToken(getState())}`;
    }

    try {
      const response = await ajax(
        requestUrl,
        method,
        requestData,
        useToken && sessionToken,
        headers,
      );

      payload.responseData = response ? response[0] : {};

      successFns.forEach(fn => fn(dispatch, response[0]));

    } catch (error) {
      errorFns.forEach(fn => fn(dispatch, error));
      return;

    } finally {
      finallyFns.forEach(fn => fn(dispatch));
    }
  }

  next(action);
};
