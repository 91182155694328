import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from '@/actions';
import * as selectors from '@/selectors';

const mapStateToProps = state => ({
  events: selectors.getSortedEvents(state),
  selectedEvent: selectors.getSelectedTextEvent(state),
});

const mapDispatchToProps = dispatch => ({
  onChange: e => dispatch(actions.selectTextEvent(e.target.value)),
});

const EventPicker = ({
  events,
  selectedEvent,
  onChange,
}) => (
  <select
    className='admin__select'
    value={selectedEvent}
    onChange={onChange}
  >
    <option value='' />
    {events.map(event => (
      <option
        key={event._id}
        value={event._id}
      >
        {event.summary}
      </option>
    ))}
  </select>
);

EventPicker.propTypes = {
  events: PropTypes.array,
  selectedEvent: PropTypes.string,
  onChange: PropTypes.func,
};

EventPicker.defaultProps = {
  events: [],
  selectedEvent: '',
  onChange: () => null,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventPicker);
